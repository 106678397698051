import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {} from "@convin/type/lms";
import {
    EventsResponse,
    IChatChannelResponse,
} from "@convin/type/SupervisorAssist";
import { GlobalFiltersStateType } from "@convin/contexts/GlobalFiltersStateContextProvider";
import {
    getDomain,
    grpcPayloadConstructor,
} from "@convin/utils/helper/common.helper";
import { LiveTranscriptType } from "@convin/type/WebSocketNotification";

export const supervisorAssistApiSlice = createApi({
    reducerPath: "supervisorAssistApiSlice",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://live-api.convin.ai/event",
    }),
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        getCalls: builder.query<
            EventsResponse,
            Pick<GlobalFiltersStateType, "reps" | "teams"> & {
                overall_sentiment?: "eq:negative";
                violations?: boolean;
                user_id: number;
            }
        >({
            query: ({ user_id, teams, reps, ...rest }) => {
                const filter = grpcPayloadConstructor({
                    ...(teams.length && { team_id: teams }),
                    ...(reps.length && { agent_id: reps }),
                    ...rest,
                });

                return {
                    url: `/list/${getDomain()}`,
                    method: "POST",
                    body: {
                        user_id,
                        ...(filter.length && {
                            filter,
                        }),
                    },
                };
            },
        }),

        getChatChannels: builder.query<IChatChannelResponse, number>({
            query: (supervisor_id) => ({
                url: "https://bark.convin.ai/api/get_supversior_channels",
                method: "POST",
                body: {
                    pageToken: "",
                    pageSize: 10,
                    TenantId: getDomain(),
                    SupervisorId: `${supervisor_id}`,
                },
            }),
            transformResponse: (response: IChatChannelResponse) => {
                return Array.isArray(response.getChannelResult.data)
                    ? {
                          ...response,
                          getChannelResult: {
                              ...response.getChannelResult,
                              data: response?.getChannelResult?.data?.map(
                                  (e) => ({
                                      ...e,
                                      isInitiated: e.isInitiated || false,
                                      isTyping: false,
                                  })
                              ),
                          },
                      }
                    : {
                          getChannelResult: {
                              ...response.getChannelResult,
                              data: [],
                          },
                      };
            },
        }),

        getChatHistoryByChannelId: builder.query<
            unknown | null, // to-do
            { channelId: string }
        >({
            queryFn: () => ({ data: null }),
        }),

        getAgentOnlineStatusById: builder.query<boolean, { agentId: number }>({
            queryFn: () => ({ data: false }),
        }),

        getLiveConversationTranscript: builder.query<
            {
                data: Pick<
                    LiveTranscriptType["body"]["message"],
                    "result_start_time" | "result_end_time" | "transcription"
                >[];
                length: number;
            },
            { channel_id: string }
        >({
            query: ({ channel_id }) => ({
                url: `/transcripts/${channel_id}`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetCallsQuery,
    useGetChatChannelsQuery,
    useGetLiveConversationTranscriptQuery,
} = supervisorAssistApiSlice;
