import useAuth from "@convin/hooks/useAuth";
import { conversationsApiSlice } from "@convin/redux/services/conversation/conversations.service";
import {
    analyticsDashboardApiSlice,
    useGetBulkUploadListQuery,
    useGetNotificationListQuery,
    useGetReportsDownloadQueQuery,
    useLazyGetReportsDownloadQueQuery,
} from "@convin/redux/services/home/analyticsDashboard.service";
import { useGetPrivateHistoryQuery } from "@convin/redux/services/notifications/notification.service";
import {
    DownloadReportNotification,
    GeneralNotificationHistory,
    InternalCoachingNotification,
    IPublish,
    MigrateAccountNotification,
    PublishPayload,
    SummaryNotification,
    WebSocketMessage,
} from "@convin/type/WebSocketNotification";
import {
    generateRandomNumber,
    getDomain,
    isDefined,
} from "@convin/utils/helper/common.helper";
import { showToast } from "@convin/utils/toast";
import {
    RefObject,
    PropsWithChildren,
    createContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import { CreateUpdateToastSettings } from "@convin/config/toast.config";
import { auditManagerApiSlice } from "@convin/redux/services/settings/auditManager.service";
import { useLocation } from "react-router-dom";
import {
    DashboardRoutesConfig,
    internalRoutes,
} from "@convin/config/routes.config";
import { supervisorAssistApiSlice } from "@convin/redux/services/supervisorAssist/supervisorAssist.service";
import { IChatChannel, IMessage } from "@convin/type/SupervisorAssist";
import { NotificationTab } from "@convin/type/Notification.model";
import useAudio from "@convin/hooks/useAudio";

export interface IWebSocketStateContext {
    open: boolean;
    commandId: RefObject<number | null>;
    setOpen: React.Dispatch<
        React.SetStateAction<IWebSocketStateContext["open"]>
    >;
    notificationIsUnread: boolean;
    setNotificationIsUnread: React.Dispatch<
        React.SetStateAction<IWebSocketStateContext["open"]>
    >;
    buttonRef: RefObject<HTMLButtonElement | null>;
    activeTab: NotificationTab;
    setActiveTab: React.Dispatch<React.SetStateAction<NotificationTab>>;
    socketRef: RefObject<WebSocket | null>;
    socketChatMessegeCreator: (
        notificationType: string,
        dataPayload: PublishPayload,
        receiverId: string,
        callId: string
    ) =>
        | {
              publish: IPublish;
              id: number;
          }
        | undefined;
}

export const WebSocketContext = createContext<IWebSocketStateContext>(
    {} as IWebSocketStateContext
);

export default function WebSocketContextProvider({
    children,
}: PropsWithChildren) {
    const location = useLocation();

    const isInternalRoute = internalRoutes.some((route) =>
        location.pathname.includes(route)
    );

    useGetPrivateHistoryQuery();
    useGetNotificationListQuery();

    const [open, setOpen] = useState(false);
    useGetReportsDownloadQueQuery(
        {},
        {
            skip: !open,
        }
    );
    useGetReportsDownloadQueQuery(
        {
            type: "internal",
        },
        {
            skip: !isInternalRoute && !open,
        }
    );

    useGetBulkUploadListQuery(
        {},
        {
            skip: !open,
        }
    );

    const [getDownloadedReports] = useLazyGetReportsDownloadQueQuery();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState<NotificationTab>("general");

    const socketRef = useRef<WebSocket | null>(null);
    const commandId = useRef<number | null>(
        generateRandomNumber({ min: 1000, max: 10000 })
    );
    const { user, isAuthenticated, checkDashboardVisibility } = useAuth();
    const { playAudio } = useAudio();
    const [notificationIsUnread, setNotificationIsUnread] =
        useState<boolean>(false);

    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const timerId = useRef<NodeJS.Timeout | null>(null);
    const animateNotificationIcon = () => {
        buttonRef.current?.classList.add("animate-bounce");
        if (isDefined(timerId.current)) clearInterval(timerId.current);
        timerId.current = setTimeout(() => {
            buttonRef.current?.classList.remove("animate-bounce");
        }, 3000);
    };
    const notificationCommandId = useRef<number | null>(null);
    const openConnection = () => {
        const authTokens: {
            access: string;
            refresh: string;
        } | null = JSON.parse(localStorage.getItem("authTokens") ?? "null") as {
            access: string;
            refresh: string;
        } | null;
        if (!authTokens?.access) return;
        if (!user) return;
        if (isDefined(socketRef.current)) return;
        const domain = import.meta.env.VITE_APP_BARK_DOMAIN ?? getDomain();
        if (!domain) return;

        const protocol = import.meta.env.VITE_APP_WS_PROTOCOL || "wss://";
        const serviceName = "PostCall";
        socketRef.current = new WebSocket(
            `${protocol}${
                import.meta.env.VITE_APP_BARK || "bark.convin.ai"
            }/connect/ws?X-Tenant-Name=${domain}&X-Service-Name=${serviceName}`
        );
        if (isDefined(socketRef.current)) {
            const isInternal = localStorage.getItem("is_internal") === "true";
            const socket = socketRef.current;
            const userId = isInternal
                ? BigInt(user.id)
                      .toString()
                      .split("")
                      .filter((e) => !Number.isNaN(Number(e)))
                      .join("")
                : user.id;
            commandId.current =
                commandId.current ??
                generateRandomNumber({ min: 1000, max: 10000 });
            socket.addEventListener("open", () => {
                const connectPayload = {
                    connect: {
                        data: {
                            "user-agent": navigator.userAgent,
                        },
                        name: "webapp",
                        tenantID: domain,
                        email: user.email,
                        token: `Bearer ${authTokens.access}`,
                        id: userId,
                    },
                    id: (commandId.current as number)++,
                };
                const subscribeGeneralChannelPayload = {
                    subscribe: { channel: `#${domain}_general` },
                    id: commandId.current!++,
                };
                const subscribePersonalChannelPayload = {
                    subscribe: { channel: `$${domain}_${userId}` },
                    id: commandId.current!++,
                };
                const generalHistoryCommand = {
                    history: { limit: 20, channel: `#${domain}_general` },
                    id: commandId.current!++,
                };
                const privateHistoryCommand = {
                    history: { limit: 50, channel: `$${domain}_${userId}` },
                    id: commandId.current!++,
                };
                notificationCommandId.current = commandId.current!++;
                const PersistentHistoryCommand = {
                    history: {
                        limit: 50,
                        channel: `$${domain}_${user.id}`,
                        persistent_only: true,
                    },
                    id: notificationCommandId.current,
                };
                const getUnreadCountCommand = {
                    get_unread_count: {
                        channel: `$${domain}_${user.id}`,
                    },
                    id: commandId.current!++,
                };
                const subscribeLiveEventChannel = {
                    subscribe: {
                        channel: `$${domain}_livesentiment_${userId}`,
                    },
                    id: commandId.current!++,
                };
                const subscribeChatChannelPayload = {
                    subscribe: { channel: `$${domain}_chat_${userId}` },
                    id: (commandId.current as number)++,
                };
                const subscribeNotificationChannelPayload = {
                    subscribe: {
                        channel: `$${domain}_chatnotification_${userId}`,
                    },
                    id: (commandId.current as number)++,
                };
                const subscribeChatTypingChannelPayload = {
                    subscribe: { channel: `$${domain}_chattyping_${userId}` },
                    id: (commandId.current as number)++,
                };
                const subscribeChatSeenChannelPayload = {
                    subscribe: {
                        channel: `$${domain}_chatseen_${userId}`,
                    },
                    id: (commandId.current as number)++,
                };
                const subscribeOnlineStatusChannelPayload = {
                    subscribe: {
                        channel: `#${domain}_onlinestatus_${userId}`,
                    },
                    id: (commandId.current as number)++,
                };

                socket.send(JSON.stringify(connectPayload));
                socket.send(
                    JSON.stringify(subscribeGeneralChannelPayload) +
                        "\n" +
                        JSON.stringify(generalHistoryCommand)
                );

                socket.send(JSON.stringify(getUnreadCountCommand));
                socket.send(
                    JSON.stringify(subscribePersonalChannelPayload) +
                        "\n" +
                        JSON.stringify(privateHistoryCommand) +
                        "\n" +
                        JSON.stringify(PersistentHistoryCommand)
                );
                if (
                    checkDashboardVisibility(
                        DashboardRoutesConfig.SupervisorAssist
                    )
                ) {
                    socket.send(JSON.stringify(subscribeLiveEventChannel));
                    socket.send(
                        JSON.stringify(subscribeNotificationChannelPayload) +
                            "\n" +
                            JSON.stringify(subscribeChatChannelPayload)
                    );
                    socket.send(
                        JSON.stringify(subscribeChatTypingChannelPayload) +
                            "\n" +
                            JSON.stringify(subscribeChatSeenChannelPayload) +
                            "\n" +
                            JSON.stringify(subscribeOnlineStatusChannelPayload)
                    );
                }
            });
            socket.addEventListener("message", (event) => {
                try {
                    const data = JSON.parse(event.data) as WebSocketMessage;
                    //Check for pong
                    if (!Object.keys(data).length) {
                        //Send Ping
                        socket.send(JSON.stringify({}));
                    }
                    if (data.get_unread_count) {
                        dispatch(
                            analyticsDashboardApiSlice.util.updateQueryData(
                                "getNotificationList",
                                undefined,
                                (draft) => ({
                                    ...draft,
                                    unreadCount:
                                        data.get_unread_count?.unread_count ??
                                        null,
                                })
                            )
                        );
                    }

                    //for notification history
                    if (isDefined(data?.history)) {
                        const messageData = data.history
                            ?.messages as GeneralNotificationHistory[];

                        if (
                            data.id === notificationCommandId.current &&
                            messageData?.length
                        ) {
                            dispatch(
                                analyticsDashboardApiSlice.util.updateQueryData(
                                    "getNotificationList",
                                    undefined,
                                    (draft) => {
                                        draft.notifications = messageData;
                                        draft.lastEvaluatedKey =
                                            data.history?.last_evaluated_key ||
                                            null;
                                        return draft;
                                    }
                                )
                            );
                        }
                    }
                    if (isDefined(data?.push)) {
                        const messageData = data.push.message.data;
                        const channel = data.push.channel;
                        if ("notification_id" in messageData) {
                            const notification = data.push
                                .message as GeneralNotificationHistory;
                            dispatch(
                                analyticsDashboardApiSlice.util.updateQueryData(
                                    "getNotificationList",
                                    undefined,
                                    (draft) => {
                                        if (!notification.metadata.is_updated) {
                                            draft.notifications.unshift({
                                                data: messageData,
                                                metadata: notification.metadata,
                                            });
                                            draft.unreadCount =
                                                draft.unreadCount
                                                    ? draft.unreadCount + 1
                                                    : null;
                                        } else {
                                            draft.notifications =
                                                draft.notifications.map((e) =>
                                                    e.data.notification_id ===
                                                    notification.data
                                                        .notification_id
                                                        ? {
                                                              data: messageData,
                                                              metadata:
                                                                  notification.metadata,
                                                          }
                                                        : e
                                                );
                                        }
                                        return draft;
                                    }
                                )
                            );
                        }
                        if ("notification_type" in messageData) {
                            if (messageData.notification_type === "summary") {
                                showToast({
                                    type: "success",
                                    message: `Summary has been generated for meeting ${messageData?.response.meeting_title}`,
                                });
                                const res =
                                    messageData?.response as SummaryNotification["response"];
                                dispatch(
                                    conversationsApiSlice.util.updateQueryData(
                                        "getConversationDetails",
                                        messageData?.response.meeting_id?.toString(),
                                        (draft) => {
                                            Object.assign(draft, {
                                                ...draft,
                                                meeting_summary: res.summary,
                                            });
                                        }
                                    )
                                );
                            }
                            if (
                                messageData.notification_type ===
                                "assessment_status"
                            ) {
                                showToast({
                                    message: `${messageData?.response.title}, ${messageData?.response.message}`,
                                    ...CreateUpdateToastSettings,
                                });
                            }
                            if (
                                messageData.notification_type === "download" ||
                                messageData.notification_type ===
                                    "internal_report"
                            ) {
                                if (
                                    messageData.response.task_status ===
                                        "completed" ||
                                    messageData.response.task_status ===
                                        "failed"
                                ) {
                                    animateNotificationIcon();
                                }
                                if (
                                    messageData.response.task_status ===
                                    "completed"
                                ) {
                                    showToast({
                                        message:
                                            "Your report is ready. Download it from the notification tab.",
                                        ...CreateUpdateToastSettings,
                                    });
                                }
                                const res =
                                    messageData?.response as DownloadReportNotification["response"];

                                dispatch(
                                    analyticsDashboardApiSlice.util.updateQueryData(
                                        "getReportsDownloadQue",
                                        {
                                            ...(messageData.notification_type ===
                                                "internal_report" && {
                                                type: "internal",
                                            }),
                                        },
                                        (draft) => {
                                            const reportExists =
                                                draft.results.find(
                                                    (e) => e.id === res.task_id
                                                );
                                            if (!reportExists) {
                                                getDownloadedReports({
                                                    ...(messageData.notification_type ===
                                                        "internal_report" && {
                                                        type: "internal",
                                                    }),
                                                });
                                                return draft;
                                            }
                                            return {
                                                ...draft,
                                                count: draft.count + 1,
                                                results: draft.results.map(
                                                    (e) =>
                                                        e.id === res.task_id
                                                            ? {
                                                                  ...e,
                                                                  task_status:
                                                                      res.task_status,
                                                                  report_url:
                                                                      res.url,
                                                              }
                                                            : e
                                                ),
                                            };
                                        }
                                    )
                                );
                                setNotificationIsUnread(true);
                                setActiveTab(
                                    messageData.notification_type ===
                                        "internal_report"
                                        ? "internal"
                                        : "downloads"
                                );
                            }

                            if (
                                messageData.notification_type === "calibration"
                            ) {
                                const { response } = messageData;
                                dispatch(
                                    auditManagerApiSlice.util.updateQueryData(
                                        "getRecalibrationStatus",
                                        response.calibration_id,
                                        (draft) => ({
                                            ...draft,
                                            [response.object_id]: {
                                                status: response.status,
                                            },
                                        })
                                    )
                                );
                            }
                            if (
                                messageData.notification_type ===
                                    "internal_coachings" ||
                                messageData.notification_type ===
                                    "migrate_account"
                            ) {
                                if (isInternalRoute) {
                                    animateNotificationIcon();
                                }

                                const res = messageData?.response as
                                    | InternalCoachingNotification["response"]
                                    | MigrateAccountNotification["response"];

                                dispatch(
                                    analyticsDashboardApiSlice.util.updateQueryData(
                                        "getReportsDownloadQue",
                                        { type: "internal" },
                                        (draft) => {
                                            const reportExists =
                                                draft.results.find(
                                                    (e) =>
                                                        e.id ===
                                                        res.report_metadata_id
                                                );
                                            if (!reportExists) {
                                                getDownloadedReports({
                                                    type: "internal",
                                                });
                                                return draft;
                                            }
                                            return {
                                                ...draft,
                                                count: draft.count + 1,
                                                results: draft.results.map(
                                                    (e) =>
                                                        e.id ===
                                                        res.report_metadata_id
                                                            ? {
                                                                  ...e,
                                                                  task_status:
                                                                      res.task_status,
                                                              }
                                                            : e
                                                ),
                                            };
                                        }
                                    )
                                );

                                setNotificationIsUnread(true);
                                setActiveTab("internal");
                            }
                            if (
                                messageData.notification_type === "bulk_upload"
                            ) {
                                const { response } = messageData;
                                dispatch(
                                    analyticsDashboardApiSlice.util.updateQueryData(
                                        "getBulkUploadList",
                                        {},
                                        (draft) => {
                                            return {
                                                ...draft,
                                                results: draft.results.map(
                                                    (e) =>
                                                        response.id === e.id
                                                            ? {
                                                                  ...e,
                                                                  status: response.status,
                                                                  status_file:
                                                                      "status_file" in
                                                                      response.metadata
                                                                          ? response
                                                                                .metadata
                                                                                .status_file
                                                                          : null,
                                                                  metadata:
                                                                      response.status ===
                                                                      "pending"
                                                                          ? {
                                                                                processed:
                                                                                    response.processed,
                                                                                total: response.total,
                                                                            }
                                                                          : response.metadata,
                                                              }
                                                            : e
                                                ),
                                            };
                                        }
                                    )
                                );
                                if (response.status === "completed") {
                                    const { metadata, type } = response;
                                    animateNotificationIcon();
                                    setNotificationIsUnread(true);
                                    setActiveTab("uploads");
                                    const isUserList = type === "USER_LIST";
                                    if (
                                        (metadata["failed"] > 0 &&
                                            metadata["success"] === 0) ||
                                        metadata.total === 0
                                    ) {
                                        showToast({
                                            message: `Failed to ${
                                                isUserList
                                                    ? "update User"
                                                    : "create Qms"
                                            }  entries`,
                                            ...CreateUpdateToastSettings,
                                            type: "error",
                                        });
                                        return;
                                    }

                                    showToast({
                                        message: isUserList
                                            ? "User list has been updated"
                                            : "Qms entries have been created",
                                        ...CreateUpdateToastSettings,
                                    });
                                }
                            }
                        }
                        if ("action" in messageData) {
                            if (messageData.action === "NOTIFICATION") {
                                if (
                                    isDefined(
                                        messageData?.data?.notification
                                    ) &&
                                    channel ===
                                        `$${getDomain()}_chatnotification_${
                                            user.id
                                        }`
                                ) {
                                    if (
                                        messageData.data.notification
                                            .notificationType ===
                                            "initialMessage" &&
                                        isDefined(
                                            messageData?.data?.savedNotification
                                        )
                                    ) {
                                        playAudio("notification");
                                        dispatch(
                                            supervisorAssistApiSlice.util.updateQueryData(
                                                "getChatChannels",
                                                user.id,
                                                (draft) => {
                                                    const isChannelExist =
                                                        draft.getChannelResult.data.find(
                                                            (e) =>
                                                                e.channel ===
                                                                `${getDomain()}_${
                                                                    messageData.callId
                                                                }`
                                                        );
                                                    draft.getChannelResult.data =
                                                        isChannelExist
                                                            ? draft.getChannelResult.data.map(
                                                                  (e) => {
                                                                      if (
                                                                          e.channel ===
                                                                          `${getDomain()}_${
                                                                              messageData.callId
                                                                          }`
                                                                      ) {
                                                                          return messageData
                                                                              .data
                                                                              .savedNotification as IChatChannel;
                                                                      }
                                                                      return e;
                                                                  }
                                                              )
                                                            : [
                                                                  ...draft
                                                                      .getChannelResult
                                                                      .data,
                                                                  {
                                                                      ...(messageData
                                                                          .data
                                                                          .savedNotification as IChatChannel),
                                                                  },
                                                              ];
                                                }
                                            )
                                        );
                                    }
                                    if (
                                        messageData.data.notification
                                            .notificationType === "end"
                                    ) {
                                        dispatch(
                                            supervisorAssistApiSlice.util.updateQueryData(
                                                "getChatChannels",
                                                user.id,
                                                (draft) => {
                                                    draft.getChannelResult.data =
                                                        draft.getChannelResult.data.filter(
                                                            (e) =>
                                                                e.channel !==
                                                                `${getDomain()}_${
                                                                    messageData.callId
                                                                }`
                                                        );
                                                }
                                            )
                                        );
                                    }
                                    if (
                                        messageData.data.notification
                                            .notificationType === "isInitiated"
                                    ) {
                                        dispatch(
                                            supervisorAssistApiSlice.util.updateQueryData(
                                                "getChatChannels",
                                                user.id,
                                                (draft) => {
                                                    draft.getChannelResult.data =
                                                        draft.getChannelResult.data.map(
                                                            (e) => {
                                                                if (
                                                                    e.channel ===
                                                                    `${getDomain()}_${
                                                                        messageData.callId
                                                                    }`
                                                                ) {
                                                                    return {
                                                                        ...e,
                                                                        isInitiated:
                                                                            messageData
                                                                                .data
                                                                                .notification
                                                                                ?.isInitiated,
                                                                    };
                                                                }
                                                                return e;
                                                            }
                                                        );
                                                }
                                            )
                                        );
                                    }
                                }
                            }
                            if (messageData.action === "CHAT") {
                                if (
                                    channel ===
                                    `$${getDomain()}_chat_${user.id}`
                                ) {
                                    if (messageData.senderId === `${user.id}`) {
                                        dispatch(
                                            supervisorAssistApiSlice.util.updateQueryData(
                                                "getChatChannels",
                                                user.id,
                                                (draft) => {
                                                    draft.getChannelResult.data =
                                                        draft.getChannelResult.data.map(
                                                            (e) => {
                                                                if (
                                                                    e.channel ===
                                                                    `${getDomain()}_${
                                                                        messageData.callId
                                                                    }`
                                                                ) {
                                                                    return {
                                                                        ...e,
                                                                        messages:
                                                                            e.messages.map(
                                                                                (
                                                                                    e
                                                                                ) => {
                                                                                    if (
                                                                                        e.tempMsgId ===
                                                                                        messageData
                                                                                            .data
                                                                                            .message
                                                                                            ?.tempMsgId
                                                                                    ) {
                                                                                        return messageData
                                                                                            .data
                                                                                            .message as IMessage;
                                                                                    }
                                                                                    return e;
                                                                                }
                                                                            ),
                                                                    };
                                                                }
                                                                return e;
                                                            }
                                                        );
                                                }
                                            )
                                        );
                                    } else {
                                        playAudio("notification");
                                        dispatch(
                                            supervisorAssistApiSlice.util.updateQueryData(
                                                "getChatChannels",
                                                user.id,
                                                (draft) => {
                                                    draft.getChannelResult.data =
                                                        draft.getChannelResult.data.map(
                                                            (e) => {
                                                                if (
                                                                    e.channel ===
                                                                    `${getDomain()}_${
                                                                        messageData.callId
                                                                    }`
                                                                ) {
                                                                    return {
                                                                        ...e,
                                                                        messages:
                                                                            [
                                                                                messageData
                                                                                    .data
                                                                                    ?.message as IMessage,
                                                                                ...e.messages,
                                                                            ],
                                                                        agentSeenCounter:
                                                                            e.agentSeenCounter !=
                                                                            undefined
                                                                                ? e.agentSeenCounter +
                                                                                  1
                                                                                : 1,
                                                                    };
                                                                }
                                                                return e;
                                                            }
                                                        );
                                                }
                                            )
                                        );
                                    }
                                }
                            }
                            if (messageData.action === "SEEN") {
                                if (
                                    channel ===
                                    `$${getDomain()}_chatseen_${user.id}`
                                ) {
                                    dispatch(
                                        supervisorAssistApiSlice.util.updateQueryData(
                                            "getChatChannels",
                                            user.id,
                                            (draft) => {
                                                draft.getChannelResult.data =
                                                    draft.getChannelResult.data.map(
                                                        (e) => {
                                                            if (
                                                                e.channel ===
                                                                `${getDomain()}_${
                                                                    messageData.callId
                                                                }`
                                                            ) {
                                                                return {
                                                                    ...e,
                                                                    agentSeenCounter: 0,
                                                                    messages:
                                                                        e.messages.map(
                                                                            (
                                                                                e
                                                                            ) => {
                                                                                if (
                                                                                    isDefined(
                                                                                        messageData
                                                                                            ?.data
                                                                                            ?.seen
                                                                                    ) &&
                                                                                    e.messageId ===
                                                                                        messageData
                                                                                            .data
                                                                                            .seen
                                                                                            ?.messageId
                                                                                ) {
                                                                                    return {
                                                                                        ...e,
                                                                                        seen: messageData
                                                                                            .data
                                                                                            .seen
                                                                                            .seen,
                                                                                    };
                                                                                }
                                                                                return e;
                                                                            }
                                                                        ),
                                                                };
                                                            }
                                                            return e;
                                                        }
                                                    );
                                            }
                                        )
                                    );
                                }
                            }
                            if (messageData.action === "TYPING") {
                                if (
                                    channel ===
                                    `$${getDomain()}_chattyping_${user.id}`
                                ) {
                                    if (messageData.senderId !== `${user.id}`) {
                                        dispatch(
                                            supervisorAssistApiSlice.util.updateQueryData(
                                                "getChatChannels",
                                                user.id,
                                                (draft) => {
                                                    draft.getChannelResult.data =
                                                        draft.getChannelResult.data.map(
                                                            (e) => {
                                                                if (
                                                                    e.channel ===
                                                                    `${getDomain()}_${
                                                                        messageData.callId
                                                                    }`
                                                                ) {
                                                                    return {
                                                                        ...e,
                                                                        isTyping:
                                                                            messageData
                                                                                .data
                                                                                .typing
                                                                                ?.isTyping,
                                                                    };
                                                                }
                                                                return e;
                                                            }
                                                        );
                                                }
                                            )
                                        );
                                    }
                                }
                            }
                            if (messageData.action === "ONLINESTATUS") {
                            }
                        }
                    }
                } catch (err) {
                    console.error(err);
                }
            });
            socket.addEventListener("close", async (event) => {
                const code = event.code;
                socketRef.current = null;
                commandId.current = null;
                const skipReConnection = [1005, 3004, 109];
                if (!skipReConnection.includes(code)) {
                    openConnection();
                } else {
                    try {
                        openConnection();
                    } catch (err) {}
                }
            });
        }
    };

    const socketChatMessegeCreator = (
        notificationType: string,
        dataPayload: PublishPayload,
        receiverId: string,
        callId: string
    ) => {
        let finaldata;
        if (user) {
            const domain = getDomain();
            const senderChannel =
                notificationType === "message"
                    ? `$${domain}_chat_${user.id}`
                    : notificationType === "seen"
                    ? `$${domain}_chatseen_${user.id}`
                    : notificationType === "typing"
                    ? `$${domain}_chattyping_${user.id}`
                    : notificationType === "onlinestatus"
                    ? `$${domain}_chatonlinestatus_${user.id}`
                    : `$${domain}_chatnotification_${user.id}`;

            const reciverChannel =
                notificationType === "message"
                    ? `$${domain}_chat_${receiverId}`
                    : notificationType === "seen"
                    ? `$${domain}_chatseen_${receiverId}`
                    : notificationType === "typing"
                    ? `$${domain}_chattyping_${receiverId}`
                    : notificationType === "onlinestatus"
                    ? `$${domain}_chatonlinestatus_${receiverId}`
                    : `$${domain}_chatnotification_${receiverId}`;

            const action =
                notificationType === "message"
                    ? "CHAT"
                    : notificationType === "seen"
                    ? "SEEN"
                    : notificationType === "typing"
                    ? "TYPING"
                    : notificationType === "onlinestatus"
                    ? "ONLINESTATUS"
                    : "NOTIFICATION";

            const publishObject: IPublish = {
                channel: senderChannel,
                data: {
                    action: action,
                    sender: senderChannel,
                    receiver: reciverChannel,
                    isSent: true,
                    tenantId: domain,
                    senderId: `${user.id}`,
                    receiverId: `${receiverId}`,
                    data: dataPayload,
                    callId: `${callId}`,
                },
            };
            finaldata = {
                publish: publishObject,
                id: (commandId.current as number)++,
            };
        }
        return finaldata;
    };

    useEffect(() => {
        const socket = socketRef.current;
        if (isDefined(user) && !isDefined(socket) && isAuthenticated)
            openConnection();
        return () => {
            if (isDefined(socket) && !isAuthenticated) {
                socket.close();
                socketRef.current = null;
            }
        };
    }, [isAuthenticated]);

    return (
        <WebSocketContext
            value={{
                open,
                setOpen,
                notificationIsUnread,
                setNotificationIsUnread,
                buttonRef,
                activeTab,
                setActiveTab,
                socketRef,
                commandId,
                socketChatMessegeCreator,
                // internalNotifications,
            }}
        >
            {children}
        </WebSocketContext>
    );
}
