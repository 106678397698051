import {
    ConversationListFilters,
    ConversationScoreType,
    RatingQuestionOptions,
} from "@convin/type/Filters.model";
import { datekeys } from "./default.config";
import { Transcript } from "@convin/type/Conversation";

export const initialConversationListFilterState: ConversationListFilters = {
    conversationScore: [null, null],
    conversationScoreName: null,
    auditType: null,
    auditDateKey: datekeys.last30days,
    joiningDateKey: null,
    auditors: [],
    templateCategories: {},
    templateQuestions: {},
    isDraftAudit: false,
    auditFeedbackStatus: null,
    client: null,
    searchKeywords: {
        keywords: [],
        isInCall: true,
        saidByClient: false,
        saidByOwner: false,
    },
    tags: { tagIds: [], logic: "or", negate: false },
    callTypes: { typeIds: [], logic: "or", negate: false },
    questions: {
        byClient: [0, 100],
        byOwner: [0, 100],
    },
    topics: {
        id: null,
        inCall: true,
    },
    conversationSkills: {
        ownerTalkRatio: [0, 100],
        clientTalkRatio: [0, 100],
        ownerLongestMonologue: [0, 100],
        clientLongestMonologue: [0, 100],
        interactivity: [-10, 10],
        interruptionCount: [null, null],
        patience: [0, 100],
        talktime: [null, null],
    },
    recordingMedium: null,
    stage: null,
    processingStatus: "processed",
    scoresense: {
        lead: null,
        csat: null,
        collection: null,
    },
    analyticsTemplate: null,
    attribute: {
        category: null,
        type: null,
        value: null,
    },
    dispositionTemplate: {
        template: null,
        level1: null,
        level2: null,
        level3: null,
        disposition_scope: null,
    },
    agentTenure: null,
};

export const ratingQuestionValueMap = (
    val: RatingQuestionOptions
): Partial<{ gte: number; lt: number }> => {
    switch (val) {
        case "5<":
            return { lt: 5 };
        case "7<":
            return { lt: 7 };
        case "7>":
            return { gte: 7 };
        default:
            return {};
    }
};

export const ratingQuestionValueReverseMap = (
    val: Partial<{ gte: number; lt: number }>
): RatingQuestionOptions | null => {
    if (val.lt === 5) {
        return "5<";
    } else if (val.lt === 7) {
        return "7<";
    } else if (val.gte === 7) {
        return "7>";
    } else {
        return null;
    }
};

export const ratingQuestionOptions: Array<{
    id: RatingQuestionOptions;
    label: string;
}> = [
    {
        id: "5<",
        label: "5 <",
    },
    {
        id: "7<",
        label: "7 <",
    },
    {
        id: "7>",
        label: "7 >",
    },
    {
        id: -1,
        label: "NA",
    },
];

export const conversationScoreOptions: Array<{
    id: ConversationScoreType;
    value: string;
}> = [
    { id: 1, value: "Good" },
    { id: 0, value: "Average" },
    { id: -1, value: "Need Attention" },
];

export const presentInCallOptions: Array<{
    id: "true" | "false";
    label: string;
}> = [
    { id: "true", label: "Yes" },
    { id: "false", label: "No" },
];

export const playbackOptions = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

export const speakerTypeMap: Record<Transcript["speaker_type"], string> = {
    client: "Customer",
    owner: "Agent",
    participant: "Participant",
};

export const sentimentTypes: Array<{
    id: "positive" | "negative";
    label: string;
}> = [
    { id: "positive", label: "Positive" },
    { id: "negative", label: "Negative" },
];

export const conversationSkillsLabelMap: Record<
    keyof ConversationListFilters["conversationSkills"],
    { label: string; unit: string }
> = {
    clientLongestMonologue: {
        label: "Longest Other Party Monologue (in Minutes)",
        unit: "MIN",
    },
    clientTalkRatio: { label: "Other Party Talk Ratio", unit: "%" },
    interactivity: { label: "Interactivity", unit: "" },
    interruptionCount: { label: "Interruption Count", unit: "" },
    ownerLongestMonologue: {
        label: "Longest Rep Monologue (in Minutes)",
        unit: "MIN",
    },
    ownerTalkRatio: { label: "Rep Talk Ratio", unit: "%" },
    patience: { label: "Patience (in Seconds)", unit: "SEC" },
    talktime: { label: "Talktime (in Seconds)", unit: "SEC" },
};
