import {
    searchDataFields,
    searchDataModels,
    searchDataTypes,
} from "@convin/config/searchData.config";
import {
    GlobalFiltersStateType,
    initialDrawerState,
} from "@convin/contexts/GlobalFiltersStateContextProvider";
import { AccountSearchPayload } from "@convin/type/Account";
import { AnalyticsParameterDetailsPayload } from "@convin/type/Analytics";
import { MeetingTypeConst, NumericRange } from "@convin/type/Common";
import {
    ConversationSearchDecodedFilters,
    ConversationSkills,
    SearchKeywords,
} from "@convin/type/Conversation";
import { FiltersPayload } from "@convin/type/CustomDashboard";
import {
    ConversationListFilters,
    DrawerFiltersType,
    insightsFilterType,
    TemplateCategoryFilter,
    TemplateQuestionFilter,
} from "@convin/type/Filters.model";
import { FieldType, SearchFilter } from "@convin/type/Search";
import { CustomTrackingPayload } from "@convin/type/customerIntelligence";
import { getTimeZone } from "@convin/utils/helper";

import { ratingQuestionValueMap } from "@convin/config/conversation.config";
import isEqual from "lodash.isequal";
import { getInsightValue, isDefined } from "../helper/common.helper";
import { ScoreObject } from "@convin/type/Audit";
import { tenure_bucket } from "@convin/type/Filter";

type DateParam = Array<string | number | null> | undefined;
type DurationParam = Array<number | null>;
type TeamParm = Array<number> | undefined | null;
type RepParam = Array<number> | undefined | null;

const getUTCDateSearchAtrribute = (
    date: Array<string | number | null | undefined>,
    excludeTime?: boolean
): SearchFilter["search_attributes"] => {
    const [startDate, endDate] = date;
    return {
        // This is to fix the offset by one day. While converting to iso string for start date is given one day before
        ...(isDefined(startDate) && {
            gte: formatDate(
                new Date(
                    new Date(
                        new Date(
                            typeof startDate === "number"
                                ? startDate * 1000
                                : startDate
                        )
                    ).toUTCString()
                ).toISOString(),
                true,
                excludeTime
            ),
        }),

        ...(isDefined(endDate) && {
            lte: formatDate(
                new Date(
                    new Date(
                        new Date(
                            typeof endDate === "number"
                                ? endDate * 1000
                                : endDate
                        )
                    ).toUTCString()
                ).toISOString(),
                false,
                excludeTime
            ),
        }),
    };
};

export const formatDate = (
    epoch: string | number | null,
    start: boolean = false,
    excludeTime?: boolean
): string | null => {
    if (!isDefined(epoch)) {
        return null;
    }
    if (start) {
    }
    const date = new Date(epoch);

    const yy = date.getFullYear();

    let mm: string | number = date.getMonth() + 1;
    let dd: string | number = date.getDate();

    dd = dd < 10 ? `0${dd}` : dd;
    mm = mm < 10 ? `0${mm}` : mm;

    if (excludeTime) return `${yy}-${mm}-${dd}`;

    return date.toISOString();
};

export const convertDaysToDateTime = (
    days: number,
    isStart: boolean
): string | null => {
    const currentDate = new Date();
    const targetDate = new Date(
        currentDate.setDate(currentDate.getDate() - days)
    );

    // Pass the timestamp of the targetDate to formatDate
    return formatDate(targetDate.getTime(), isStart);
};

export type StaticFiltersParam = {
    meetingType?: MeetingTypeConst;
    date?: DateParam;
    duration?: DurationParam;
    teams_id?: TeamParm;
    reps_id?: RepParam;
    template?: number | null;
};
export const setDurationSearchData = (
    duration: DurationParam,
    dashboard?: "account",
    convinInsights?: insightsFilterType
): SearchFilter => {
    const search_attributes = {
        ...(isDefined(duration[0]) && {
            gte: duration[0],
        }),
        ...(isDefined(duration[1]) && {
            lte: duration[1],
        }),
    };

    return {
        ...(dashboard === "account"
            ? {
                  name: isDefined(convinInsights?.option_type)
                      ? searchDataFields.sales_task__meeting__stats__duration
                      : searchDataFields.duration,
                  model: searchDataModels.opportunity,
              }
            : {
                  name: searchDataFields.stats__duration,
                  model: searchDataModels.meeting,
              }),

        type: searchDataTypes.BigIntegerField,
        search_attributes,
    };
};

export const setMeetingTypeSearchData = (meetingType: string) => {
    return {
        name: searchDataFields.meeting_type,
        model: searchDataModels.meeting,
        type: searchDataTypes.CharField,
        search_attributes: {
            exact: meetingType,
        },
    };
};

export const setDateSearchData = (
    date: DateParam = [],
    dashboard?: "account",
    dateKey?: string
): SearchFilter => {
    return {
        ...(dashboard === "account"
            ? {
                  name: searchDataFields.sales_task__created,
                  model: searchDataModels.opportunity,
              }
            : {
                  name: searchDataFields.start_time,
                  model: searchDataModels.meeting,
              }),

        type: searchDataTypes.DateTimeField,
        search_attributes: {
            ...getUTCDateSearchAtrribute(date),
            ...(!!dateKey && {
                expr: dateKey,
            }),
        },
    };
};

export const setMeetingTagsSearchData = ({
    tagIds,
    negate,
    logic,
}: ConversationListFilters["tags"]): SearchFilter => ({
    name: searchDataFields.tags__id,
    model: searchDataModels.meeting,
    type: searchDataTypes.AutoField,
    search_attributes: tagIds?.length
        ? {
              in: tagIds,
              ...(logic === "and" && { and: true }),
              ...(negate && { negate }),
          }
        : { exact: null },
});

export const setCallTypesSearchData = ({
    typeIds,
    logic,
    negate,
}: ConversationListFilters["callTypes"]): SearchFilter => ({
    name: searchDataFields.call_types__id,
    model: searchDataModels.meeting,
    type: searchDataTypes.AutoField,
    search_attributes: typeIds?.length
        ? {
              in: typeIds,
              ...(logic === "and" && { and: true }),
              ...(negate && { negate }),
          }
        : { exact: null },
});

export const setStageSearchData = (
    stage: number,
    dashboard?: "account"
): SearchFilter => ({
    ...(dashboard === "account"
        ? {
              name: searchDataFields.stage__id,
              model: searchDataModels.opportunity,
          }
        : {
              name: searchDataFields.sales_stage__id,
              model: searchDataModels.meeting,
          }),

    type: searchDataTypes.AutoField,
    search_attributes: {
        exact: stage || null,
    },
});

export const setDispositionTemplateData = (
    {
        template,
        level1,
        level2,
        level3,
    }: ConversationListFilters["dispositionTemplate"],
    model: searchDataModels,
    name: Record<"templateName" | "resultName" | "dispositionName", FieldType>
): SearchFilter[] => {
    const searchData: SearchFilter[] = [];

    if (isDefined(template)) {
        searchData.push({
            name: name.templateName,
            model: model,
            type: searchDataTypes.BigAutoField,
            search_attributes: {
                exact: template,
            },
        });
        if (isDefined(level1)) {
            searchData.push({
                name: name.resultName,
                model: model,
                type: searchDataTypes.JSONField,
                search_attributes: {
                    level_1__contains: level1,
                },
            });
            if (isDefined(level2)) {
                searchData.push({
                    name: name.resultName,
                    model: model,
                    type: searchDataTypes.JSONField,
                    search_attributes: {
                        level_2__contains: level2,
                    },
                });
                if (isDefined(level3)) {
                    searchData.push({
                        name: name.dispositionName,
                        model: model,
                        type: searchDataTypes.BigAutoField,
                        search_attributes: {
                            exact: level3,
                        },
                    });
                }
            }
        }
    }

    return searchData;
};

export const setMeetingSalesTaskSearchData = (stage: number): SearchFilter => ({
    name: searchDataFields.sales_task__id,
    model: searchDataModels.meeting,
    type: searchDataTypes.AutoField,
    search_attributes: {
        exact: stage || null,
    },
});

export const setPhraseSearchData = (phrase: string | number): SearchFilter => ({
    name: searchDataFields.combined_ts_vector,
    model: searchDataModels.transcript,
    type: searchDataTypes.SearchVectorField,
    search_attributes: {
        exact: phrase,
        negate: false,
    },
});

export const setSaidBySearchData = (data: {
    owner: boolean;
    client: boolean;
}) => {
    const { owner, client } = data;
    return {
        name: searchDataFields.speaker_type,
        model: searchDataModels.speakerTranscript,
        type: searchDataTypes.CharField,
        search_attributes: {
            iregex:
                client && !owner ? "owner" : !client && owner ? "client" : null,
        },
    };
};

export const setTemplateSearchData = (
    template: number | null | undefined
): SearchFilter => ({
    name: searchDataFields.meeting_score__template__id,
    model: searchDataModels.meeting,
    type: searchDataTypes.AutoField,
    search_attributes: { exact: template ?? null },
});

export const setAccountTemplateSearchData = (
    template: number | null | undefined
): SearchFilter => ({
    name: searchDataFields.sales_task__account_score__template__id,
    model: searchDataModels.opportunity,
    type: searchDataTypes.AutoField,
    search_attributes: { exact: template ?? null },
});

export const getGteLteSearchAttribute = (
    range: NumericRange,
    factor: number = 1,
    isConversationScore?: boolean
) => ({
    ...(isDefined(range[0]) ? { gte: range[0] / factor } : {}),
    ...(isDefined(range[1])
        ? isConversationScore
            ? { lt: range[1] / factor }
            : { lte: range[1] / factor }
        : {}),
});

export const setConversationScoreSearchData = (
    score: NumericRange
): SearchFilter => ({
    name: searchDataFields.meeting_score__percent,
    model: searchDataModels.meeting,
    type: searchDataTypes.FloatField,
    search_attributes: getGteLteSearchAttribute(score, 100, true),
});

export const setKeywordSearchData = (data: SearchKeywords) => {
    const searchData = [] as SearchFilter[];

    const formattedKeywords = data.keywords
        .map((keyword) => keyword.toLowerCase())
        .join(" ");

    if (data.saidByClient === data.saidByOwner) {
        searchData.push({
            name: searchDataFields.combined_ts_vector,
            model: searchDataModels.transcript,
            type: searchDataTypes.SearchVectorField,
            search_attributes: {
                exact: formattedKeywords,
                negate: !data.isInCall,
            },
        });
    } else {
        searchData.push({
            name: searchDataFields.speaker_type,
            model: searchDataModels.speakerTranscript,
            type: searchDataTypes.CharField,
            search_attributes: {
                iregex: data.saidByClient ? "client|participant" : "owner",
            },
        });
        searchData.push({
            name: searchDataFields.speaker_transcript_ts_vector,
            model: searchDataModels.speakerTranscript,
            type: searchDataTypes.SearchVectorField,
            search_attributes: {
                exact: formattedKeywords,
                negate: !data.isInCall,
            },
        });
    }

    return searchData;
};

export const setConversationSkillsSearchData = (data: ConversationSkills) => {
    const searchData = [] as SearchFilter[];

    const common = {
        model: searchDataModels.meeting,
        type: searchDataTypes.FloatField,
    };

    if (
        !isEqual(
            data.clientLongestMonologue,
            initialDrawerState.conversationSkills.clientLongestMonologue
        )
    ) {
        searchData.push({
            name: searchDataFields.stats__longest_monologue_client,
            model: common.model,
            type: searchDataTypes.BigIntegerField,
            search_attributes: getGteLteSearchAttribute(
                data.clientLongestMonologue,
                1 / 60
            ),
        });
    }
    if (
        !isEqual(
            data.ownerLongestMonologue,
            initialDrawerState.conversationSkills.ownerLongestMonologue
        )
    ) {
        searchData.push({
            name: searchDataFields.stats__longest_monologue_owner,
            model: common.model,
            type: searchDataTypes.BigIntegerField,
            search_attributes: getGteLteSearchAttribute(
                data.ownerLongestMonologue,
                1 / 60
            ),
        });
    }
    if (
        !isEqual(
            data.clientTalkRatio,
            initialDrawerState.conversationSkills.clientTalkRatio
        )
    ) {
        searchData.push({
            name: searchDataFields.stats__client_talk_ratio,
            ...common,
            search_attributes: getGteLteSearchAttribute(
                data.clientTalkRatio,
                100
            ),
        });
    }
    if (
        !isEqual(
            data.ownerTalkRatio,
            initialDrawerState.conversationSkills.ownerTalkRatio
        )
    ) {
        searchData.push({
            name: searchDataFields.stats__owner_talk_ratio,
            ...common,
            search_attributes: getGteLteSearchAttribute(
                data.ownerTalkRatio,
                100
            ),
        });
    }
    if (
        !isEqual(
            data.interactivity,
            initialDrawerState.conversationSkills.interactivity
        )
    ) {
        searchData.push({
            name: searchDataFields.stats__interactivity,
            ...common,
            search_attributes: getGteLteSearchAttribute(data.interactivity, 1),
        });
    }

    if (
        !isEqual(
            data.interruptionCount,
            initialDrawerState.conversationSkills.interruptionCount
        )
    ) {
        searchData.push({
            name: searchDataFields.stats__owner_overlap_rate,
            ...common,
            search_attributes: getGteLteSearchAttribute(
                data.interruptionCount,
                1
            ),
        });
    }
    if (
        !isEqual(data.patience, initialDrawerState.conversationSkills.patience)
    ) {
        searchData.push({
            name: searchDataFields.stats__patience,
            ...common,
            search_attributes: getGteLteSearchAttribute(data.patience, 1),
        });
    }
    if (
        !isEqual(data.talktime, initialDrawerState.conversationSkills.talktime)
    ) {
        searchData.push({
            name: searchDataFields.stats__talk_time,
            model: common.model,
            type: searchDataTypes.BigIntegerField,
            search_attributes: getGteLteSearchAttribute(data.talktime, 1),
        });
    }

    return searchData;
};

export const setQuestionsSearchData = (
    data: ConversationSearchDecodedFilters["questions"]
) => {
    const searchData = [] as SearchFilter[];

    const common = {
        model: searchDataModels.meeting,
        type: searchDataTypes.PositiveIntegerField,
    };
    if (!isEqual(data.byClient, initialDrawerState.questions.byClient)) {
        searchData.push({
            name: searchDataFields.stats__client_question_count,
            ...common,
            search_attributes: getGteLteSearchAttribute(data.byClient, 1),
        });
    }

    if (!isEqual(data.byOwner, initialDrawerState.questions.byOwner)) {
        searchData.push({
            name: searchDataFields.stats__owner_question_count,
            ...common,
            search_attributes: getGteLteSearchAttribute(data.byOwner, 1),
        });
    }

    return searchData;
};

export const setTopicsSearchData = (
    topic: ConversationSearchDecodedFilters["topics"]
): SearchFilter => ({
    name: searchDataFields.topics__id,
    model: searchDataModels.meeting,
    type: searchDataTypes.AutoField,
    search_attributes: {
        exact: topic.id,
        negate: !topic.inCall,
    },
});

export const setRecordingMediumSearchData = (medium: string): SearchFilter => ({
    name: searchDataFields.conference_tool,
    model: searchDataModels.meeting,
    type: searchDataTypes.CharField,
    search_attributes: {
        exact: medium,
    },
});

export const setProcessingStatusSearchField = (
    status: string
): SearchFilter => ({
    name: searchDataFields.processing_status,
    model: searchDataModels.meeting,
    type: searchDataTypes.CharField,
    search_attributes: {
        exact: status,
    },
});

export const setRepJoiningSearchData = (
    date: Exclude<DateParam, undefined>,
    joiningDateKey?: string | null
): SearchFilter => ({
    name: searchDataFields.owner__org_date_joined,
    model: searchDataModels.meeting,
    type: searchDataTypes.DateTimeField,
    search_attributes: {
        ...getUTCDateSearchAtrribute(date),
        ...(!!joiningDateKey && {
            expr: joiningDateKey,
        }),
    },
});

export const setTemplateScoreSearchData = (
    id: number | null,
    categories?: TemplateCategoryFilter,
    questions?: TemplateQuestionFilter
): SearchFilter[] => {
    const searchData: SearchFilter[] = [];

    if (id) {
        if (isDefined(categories)) {
            for (const [catId, score] of Object.entries(categories)) {
                searchData.push({
                    name: searchDataFields.category_score__category__id,
                    model: searchDataModels.meeting,
                    type: searchDataTypes.AutoField,
                    search_attributes: {
                        exact: +catId,
                    },
                });
                searchData.push({
                    name: searchDataFields.category_score__category_percent,
                    model: searchDataModels.meeting,
                    type: searchDataTypes.FloatField,
                    search_attributes: getGteLteSearchAttribute(score, 100),
                });
            }
        }

        if (isDefined(questions)) {
            for (const [qId, qValue] of Object.entries(questions)) {
                searchData.push({
                    name: searchDataFields.score__question__id,
                    model: searchDataModels.meeting,
                    type: searchDataTypes.AutoField,
                    search_attributes: {
                        exact: +qId,
                    },
                });

                searchData.push({
                    name: searchDataFields.score__score_given,
                    model: searchDataModels.meeting,
                    type: searchDataTypes.FloatField,
                    search_attributes: {
                        ...(typeof qValue === "number"
                            ? { exact: qValue }
                            : ratingQuestionValueMap(qValue)),
                    },
                });
            }
        }

        searchData.push(setTemplateSearchData(id));
    }

    return searchData;
};

export const setTenureBucketSearchField = (
    agentTenure: tenure_bucket
): SearchFilter[] => {
    const data: SearchFilter[] = [];
    data.push({
        name: searchDataFields.owner__date_joined,
        model: searchDataModels.meeting,
        type: searchDataTypes.DateTimeField,
        search_attributes: {
            gte: convertDaysToDateTime(agentTenure.end, false),
            lte: convertDaysToDateTime(agentTenure.start, true),
        },
    });
    return data;
};
export const setInsightSearchData = (
    convinInsights: insightsFilterType,
    isPrimary?: boolean
): SearchFilter[] => {
    const searchData: SearchFilter[] = [];
    const { module, insights, option_type } = convinInsights;
    const hasInsightValue = getInsightValue(insights);

    let name: keyof typeof searchDataFields;
    if (module === "customer_intelligence") {
        name = hasInsightValue
            ? searchDataFields.insight_score__insight__id
            : searchDataFields.insight_score__insight__type;
    } else if (module === "ai_insights") {
        name = hasInsightValue
            ? searchDataFields.questionresult__tags__id
            : searchDataFields.questionresult__question__id;
    } else {
        name = hasInsightValue
            ? searchDataFields.search_snippet__search_phrase__id
            : searchDataFields.search_snippet__search_phrase__tab__id;
    }

    searchData.push({
        name,
        model: searchDataModels.meeting,
        type:
            module === "customer_intelligence" && !hasInsightValue
                ? searchDataTypes.CharField
                : module === "ai_insights"
                ? searchDataTypes.BigAutoField
                : searchDataTypes.AutoField,
        search_attributes: {
            exact: hasInsightValue ?? option_type,
        },
    });
    if (module !== "custom_tracking" && !isDefined(hasInsightValue)) {
        searchData.push({
            name: searchDataFields.insight_score__insight__parent_insight__id,
            model: searchDataModels.meeting,
            type: searchDataTypes.AutoField,
            search_attributes: {
                isnull: true,
            },
        });
    }
    if (module === "customer_intelligence") {
        searchData.push({
            name: searchDataFields.insight_score__is_primary,
            model: searchDataModels.meeting,
            type: searchDataTypes.BooleanField,
            search_attributes: {
                exact: isPrimary ?? true,
            },
        });
    }
    searchData.push({
        name: searchDataFields.owner__is_active,
        model: searchDataModels.meeting,
        type: searchDataTypes.BooleanField,
        search_attributes: {
            exact: true,
        },
    });

    return searchData;
};

export const setAccountTemplateScoreSearchData = (
    id: number | null,
    categories?: TemplateCategoryFilter,
    questions?: TemplateQuestionFilter
): SearchFilter[] => {
    const searchData: SearchFilter[] = [];

    if (id) {
        if (isDefined(categories)) {
            for (const [catId, score] of Object.entries(categories)) {
                searchData.push({
                    name: searchDataFields.sales_task__acc_category_score__category__id,
                    model: searchDataModels.opportunity,
                    type: searchDataTypes.AutoField,
                    search_attributes: {
                        exact: +catId,
                    },
                });
                searchData.push({
                    name: searchDataFields.sales_task__acc_category_score__category_percent,
                    model: searchDataModels.opportunity,
                    type: searchDataTypes.FloatField,
                    search_attributes: getGteLteSearchAttribute(score, 100),
                });
            }
        }

        if (isDefined(questions)) {
            for (const [qId, qValue] of Object.entries(questions)) {
                searchData.push({
                    name: searchDataFields.sales_task__account_score__acc_scores__question__id,
                    model: searchDataModels.opportunity,
                    type: searchDataTypes.AutoField,
                    search_attributes: {
                        exact: +qId,
                    },
                });

                searchData.push({
                    name: searchDataFields.sales_task__account_score__acc_scores__score_given,
                    model: searchDataModels.opportunity,
                    type: searchDataTypes.FloatField,
                    search_attributes: {
                        ...(typeof qValue === "number"
                            ? { exact: qValue }
                            : ratingQuestionValueMap(qValue)),
                    },
                });
            }
        }

        searchData.push(setAccountTemplateSearchData(id));
    }

    return searchData;
};

export const setAuditSearchData = ({
    auditType,
    auditEndDate,
    auditStartDate,
    auditors,
}: { auditType: NonNullable<ConversationListFilters["auditType"]> } & Pick<
    Partial<ConversationSearchDecodedFilters>,
    "auditEndDate" | "auditStartDate" | "auditors"
>): SearchFilter[] => {
    const data: SearchFilter[] = [];
    data.push({
        name: searchDataFields.meeting_score__auditor__id,
        model: searchDataModels.meeting,
        type: searchDataTypes.AutoField,
        search_attributes: {
            ...(Boolean(auditType) && auditors?.length
                ? { in: auditors }
                : { isnull: Boolean(auditType) ? false : true }),
        },
    });

    //is manual
    if (Boolean(auditType)) {
        if (isDefined(auditEndDate) || isDefined(auditStartDate)) {
            data.push({
                name: searchDataFields.meeting_score__last_audit_time,
                model: searchDataModels.meeting,
                type: searchDataTypes.DateTimeField,
                search_attributes: getUTCDateSearchAtrribute([
                    auditStartDate,
                    auditEndDate,
                ]),
            });
        }
        data.push({
            name: searchDataFields.score__is_ai_rated,
            model: searchDataModels.meeting,
            type: searchDataTypes.BooleanField,
            search_attributes: {
                exact: false,
            },
        });
    } else {
        //is ai
        data.push({
            name: searchDataFields.score__is_ai_rated,
            model: searchDataModels.meeting,
            type: searchDataTypes.BooleanField,
            search_attributes: {
                exact: true,
            },
        });
    }

    return data;
};

export const setAccountAuditSearchData = ({
    auditType,
    auditEndDate,
    auditStartDate,
    auditors,
}: { auditType: NonNullable<ConversationListFilters["auditType"]> } & Pick<
    Partial<ConversationSearchDecodedFilters>,
    "auditEndDate" | "auditStartDate" | "auditors"
>): SearchFilter[] => {
    const data: SearchFilter[] = [];
    data.push({
        name: searchDataFields.sales_task__account_score__auditor__id,
        model: searchDataModels.opportunity,
        type: searchDataTypes.AutoField,
        search_attributes: {
            ...(Boolean(auditType) && auditors?.length
                ? { in: auditors }
                : { isnull: Boolean(auditType) ? false : true }),
        },
    });

    //is manual
    if (Boolean(auditType)) {
        if (isDefined(auditEndDate) || isDefined(auditStartDate)) {
            data.push({
                name: searchDataFields.sales_task__account_score__last_audit_time,
                model: searchDataModels.opportunity,
                type: searchDataTypes.DateTimeField,
                search_attributes: getUTCDateSearchAtrribute([
                    auditStartDate,
                    auditEndDate,
                ]),
            });
        }
    }

    return data;
};

export const setIsInDraftFilter = (flag?: boolean): SearchFilter[] => {
    const data: SearchFilter[] = [];
    if (flag) {
        data.push({
            name: searchDataFields.score__score_given,
            model: searchDataModels.meeting,
            type: searchDataTypes.FloatField,
            search_attributes: {
                isnull: false,
            },
        });
        data.push({
            name: searchDataFields.score__is_ai_rated,
            model: searchDataModels.meeting,
            type: searchDataTypes.BooleanField,
            search_attributes: {
                exact: false,
            },
        });
        data.push({
            name: searchDataFields.score__is_in_draft,
            model: searchDataModels.meeting,
            type: searchDataTypes.BooleanField,
            search_attributes: {
                exact: true,
            },
        });
    }
    return data;
};

export const setFeedbackStatusFilter = (
    status: ConversationListFilters["auditFeedbackStatus"]
): SearchFilter[] => {
    const data: SearchFilter[] = [];
    data.push({
        name: searchDataFields.meeting_score__status,
        model: searchDataModels.meeting,
        type: searchDataTypes.CharField,
        search_attributes: {
            ...(status === "not_acknowledge"
                ? {
                      isnull: true,
                  }
                : { exact: status }),
        },
    });
    return data;
};

export const prepareScoreSenseFilter = (
    scoreSense: ConversationListFilters["scoresense"]
): SearchFilter[] => {
    const data: SearchFilter[] = [];
    if (isDefined(scoreSense.lead)) {
        data.push({
            name: searchDataFields.sales_task__account_stat__lead_classification,
            model: searchDataModels.meeting,
            type: searchDataTypes.CharField,
            search_attributes: {
                exact: scoreSense.lead,
            },
        });
    }
    if (isDefined(scoreSense.csat)) {
        data.push({
            name: searchDataFields.gptanalysisresult__score_type,
            model: searchDataModels.meeting,
            type: searchDataTypes.CharField,
            search_attributes: {
                exact: "CSAT",
            },
        });
        data.push({
            name: searchDataFields.gptanalysisresult__classification,
            model: searchDataModels.meeting,
            type: searchDataTypes.CharField,
            search_attributes: {
                exact: scoreSense.csat,
            },
        });
    }
    if (isDefined(scoreSense.collection)) {
        data.push({
            name: searchDataFields.gptanalysisresult__score_type,
            model: searchDataModels.meeting,
            type: searchDataTypes.CharField,
            search_attributes: {
                exact: "Collection",
            },
        });
        data.push({
            name: searchDataFields.gptanalysisresult__classification,
            model: searchDataModels.meeting,
            type: searchDataTypes.CharField,
            search_attributes: {
                exact: scoreSense.collection,
            },
        });
    }
    return data;
};

export const prepareAttributeFilter = (
    attribute: ConversationListFilters["attribute"]
): SearchFilter[] => {
    const data: SearchFilter[] = [];
    if (isDefined(attribute.category)) {
        data.push({
            name: searchDataFields.metadata__key__metadata_type__id,
            model: searchDataModels.meeting,
            type: searchDataTypes.AutoField,
            search_attributes: {
                exact: attribute.category,
            },
        });
    }
    if (isDefined(attribute.type)) {
        data.push({
            name: searchDataFields.metadata__key__id,
            model: searchDataModels.meeting,
            type: searchDataTypes.AutoField,
            search_attributes: {
                exact: attribute.type,
            },
        });
    }
    if (isDefined(attribute.value)) {
        data.push({
            name: searchDataFields.metadata__value,
            model: searchDataModels.meeting,
            type: searchDataTypes.JSONField,
            search_attributes: {
                exact: { value: attribute.value },
            },
        });
    }
    return data;
};

//fuction which prepares the search data for meetingtype,team,owner,duration and date
export const preparePageSearchData = ({
    teams_id,
    reps_id,
    duration,
    date,
    dashboard,
    accountReps,
    accountTeams,
    dateKey,
    convinInsights,
}: Partial<
    StaticFiltersParam & {
        dashboard: "account";
        accountReps?: number[];
        accountTeams?: number[];
        dateKey?: string;
        convinInsights?: insightsFilterType;
    }
>): SearchFilter[] => {
    const data: SearchFilter[] = [];
    if (
        (dashboard === "account" &&
            isDefined(accountTeams) &&
            accountTeams?.length) ||
        (dashboard !== "account" && isDefined(teams_id) && teams_id?.length)
    )
        data.push({
            ...(dashboard === "account"
                ? {
                      name: searchDataFields.sales_task__owner__team__id,
                      model: searchDataModels.opportunity,
                  }
                : {
                      name: searchDataFields.owner__team__id,
                      model: searchDataModels.meeting,
                  }),
            type: searchDataTypes.AutoField,
            search_attributes: {
                in:
                    dashboard === "account"
                        ? accountTeams ?? []
                        : teams_id ?? [],
            },
        });
    if (
        (dashboard === "account" &&
            isDefined(accountReps) &&
            accountReps?.length) ||
        (dashboard !== "account" && isDefined(reps_id) && reps_id?.length)
    )
        data.push({
            ...(dashboard === "account"
                ? {
                      name: searchDataFields.sales_task__owner__id,
                      model: searchDataModels.opportunity,
                  }
                : {
                      name: searchDataFields.owner__id,
                      model: searchDataModels.meeting,
                  }),

            type: searchDataTypes.AutoField,
            search_attributes: {
                in: dashboard === "account" ? accountReps ?? [] : reps_id ?? [],
            },
        });

    if (
        isDefined(duration) &&
        (isDefined(duration[0]) || isDefined(duration[1]))
    )
        data.push(setDurationSearchData(duration, dashboard, convinInsights));
    if (isDefined(date?.[0]) || isDefined(date?.[1]))
        data.push(setDateSearchData(date, dashboard, dateKey));

    return data;
};

//function which prepares payload for all the api's of customer inttelligence
export const prepareCISearchData = ({
    phrase,
    tags,
    stage,
    saidBy,
    exclude,
    meeting_type,
    min_duration,
    max_duration,
    start_date,
    end_date,
    scoresense,
    ...rest
}: {
    phrase?: string;
    stage?: number | null;
    tags?: ConversationListFilters["tags"];
    saidBy: {
        owner: boolean;
        client: boolean;
    };
    exclude: boolean;
    scoresense: ConversationListFilters["scoresense"];
} & Partial<FiltersPayload>): CustomTrackingPayload => {
    const data = [
        ...preparePageSearchData({
            ...rest,
            duration: [min_duration ?? null, max_duration ?? null],
            date: [start_date ?? null, end_date ?? null],
        }),
    ];

    data.push(setSaidBySearchData(saidBy));
    if (meeting_type) {
        data.push(setMeetingTypeSearchData(meeting_type));
    }
    if (phrase) {
        data.push(setPhraseSearchData(phrase));
    }

    if (tags?.tagIds?.length) {
        data.push(setMeetingTagsSearchData(tags));
    }

    if (stage) {
        data.push(setStageSearchData(stage));
    }

    if (isDefined(scoresense)) {
        data.push(...prepareScoreSenseFilter(scoresense));
    }

    return {
        search_data: data,
        exclude,
        timezone: getTimeZone(),
    };
};

//function which prepares payload for accounts dashboard
export const prepareAccountSearchData = (
    filters: Partial<FiltersPayload> &
        Pick<
            DrawerFiltersType,
            | "dealSize"
            | "minMaxInteractions"
            | "minLeadScore"
            | "leadType"
            | "accountQueryBy"
            | "accountSortBy"
            | "conversationScore"
            | "template"
            | "templateCategories"
            | "templateQuestions"
            | "auditType"
            | "auditors"
            | "accountTags"
        > & {
            closeDate?: {
                start: null | number | string;
                end: null | number | string;
            };
            lastContactedDate?: {
                start: null | number | string;
                end: null | number | string;
            };
            single_agent_accounts?: boolean;
            convinInsights?: insightsFilterType;
            isPrimary?: boolean;
            dispositionScope?: "account" | "meeting" | null;
        } & Partial<
            Pick<
                ConversationSearchDecodedFilters,
                "auditStartDate" | "auditEndDate"
            >
        >
): AccountSearchPayload => {
    const {
        accountSortBy,
        accountQueryBy,
        dealSize,
        minMaxInteractions,
        minLeadScore,
        lastContactedDate,
        closeDate,
        accountTags,
        leadType,
        stages_id,
        min_duration,
        max_duration,
        start_date,
        end_date,
        conversationScore,
        template,
        templateCategories,
        templateQuestions,
        auditType,
        auditStartDate,
        auditEndDate,
        auditors,
        single_agent_accounts,
        ai_disposition_template,
        dispositionScope,
        level_1: level1,
        level_2: level2,
        level_3: level3,
        accountReps,
        accountTeams,
        reps_id,
        teams_id,
        convinInsights,
        isPrimary,
        ...rest
    } = filters;
    const search_data = [
        ...preparePageSearchData({
            ...rest,
            dashboard: "account",
            accountReps: accountReps,
            accountTeams: accountTeams,
            duration: [min_duration ?? null, max_duration ?? null],
            date: [start_date ?? null, end_date ?? null],
            convinInsights,
        }),
    ];

    if (isDefined(dealSize.min) || isDefined(dealSize.max))
        search_data.push({
            name: searchDataFields.account_size,
            model: searchDataModels.opportunity,
            type: searchDataTypes.PositiveIntegerField,
            search_attributes: {
                gte: dealSize.min,
                lte: dealSize.max,
            },
        });

    if (
        isDefined(closeDate) &&
        (isDefined(closeDate?.start) || isDefined(closeDate?.end))
    )
        search_data.push({
            name: searchDataFields.close_date,
            model: searchDataModels.opportunity,
            type: searchDataTypes.DateField,
            search_attributes: getUTCDateSearchAtrribute(
                Object.values(closeDate),
                true
            ),
        });

    if (
        isDefined(lastContactedDate) &&
        (isDefined(lastContactedDate?.start) ||
            isDefined(lastContactedDate?.end))
    )
        search_data.push({
            name: searchDataFields.last_connected_date,
            model: searchDataModels.opportunity,
            type: searchDataTypes.DateField,
            search_attributes: getUTCDateSearchAtrribute(
                Object.values(lastContactedDate),
                true
            ),
        });
    if (accountTags.tagIds?.length) {
        const { tagIds, logic, negate } = accountTags;
        search_data.push({
            name: searchDataFields.sales_task__tags__id,
            model: searchDataModels.opportunity,
            type: searchDataTypes.AutoField,
            search_attributes: {
                in: tagIds,
                ...(logic === "and" && { and: true }),
                ...(negate && { negate }),
            },
        });
    }

    if (reps_id?.length) {
        search_data.push({
            name: searchDataFields.sales_task__account_score__owner__id,
            model: searchDataModels.opportunity,
            type: searchDataTypes.AutoField,
            search_attributes: {
                in: reps_id,
            },
        });
    }
    if (teams_id?.length) {
        search_data.push({
            name: searchDataFields.sales_task__account_score__owner__team__id,
            model: searchDataModels.opportunity,
            type: searchDataTypes.AutoField,
            search_attributes: {
                in: teams_id,
            },
        });
    }

    if (leadType) {
        search_data.push({
            name: searchDataFields.lead_classification,
            model: searchDataModels.opportunity,
            type: searchDataTypes.CharField,
            search_attributes: {
                exact: leadType,
            },
        });
    }

    if (isDefined(minLeadScore)) {
        search_data.push({
            name: searchDataFields.lead_score,
            model: searchDataModels.opportunity,
            type: searchDataTypes.FloatField,
            search_attributes: {
                gte: minLeadScore,
            },
        });
    }

    if (isDefined(stages_id)) {
        search_data.push({
            name: searchDataFields.stage__id,
            model: searchDataModels.opportunity,
            type: searchDataTypes.AutoField,
            search_attributes: {
                in: stages_id,
            },
        });
    }

    if (
        isDefined(conversationScore?.[0]) ||
        isDefined(conversationScore?.[1]) ||
        isDefined(template) ||
        isDefined(auditType)
    ) {
        search_data.push({
            name: searchDataFields.sales_task__account_score__id,
            model: searchDataModels.opportunity,
            type: searchDataTypes.AutoField,
            search_attributes: { isnull: false },
        });
    }

    if (
        isDefined(conversationScore?.[0]) ||
        isDefined(conversationScore?.[1])
    ) {
        search_data.push({
            name: searchDataFields.sales_task__account_score__percent,
            model: searchDataModels.opportunity,
            type: searchDataTypes.FloatField,
            search_attributes: getGteLteSearchAttribute(
                conversationScore,
                100,
                true
            ),
        });
    }

    search_data.push(
        ...setAccountTemplateScoreSearchData(
            template ?? null,
            templateCategories,
            templateQuestions
        )
    );

    if (isDefined(auditType)) {
        search_data.push(
            ...setAccountAuditSearchData({
                auditType,
                auditStartDate,
                auditEndDate,
                auditors,
            })
        );
    }
    if (isDefined(convinInsights?.option_type)) {
        const hasInsightValue = getInsightValue(convinInsights.insights);
        const moduleName = convinInsights?.module;

        const insightName =
            moduleName === "customer_intelligence"
                ? hasInsightValue
                    ? searchDataFields.sales_task__meeting__insight_score__insight__id
                    : searchDataFields.sales_task__meeting__insight_score__insight__type
                : moduleName === "ai_insights"
                ? hasInsightValue
                    ? searchDataFields.sales_task__meeting__questionresult__tags__id
                    : searchDataFields.sales_task__meeting__questionresult__question__id
                : hasInsightValue
                ? searchDataFields.sales_task__meeting__search_snippet__search_phrase__id
                : searchDataFields.sales_task__meeting__search_snippet__search_phrase__tab__id;

        const insightType =
            moduleName === "customer_intelligence" && !hasInsightValue
                ? searchDataTypes.CharField
                : moduleName === "ai_insights"
                ? searchDataTypes.BigAutoField
                : searchDataTypes.AutoField;

        search_data.push({
            name: insightName,
            model: searchDataModels.opportunity,
            type: insightType,
            search_attributes: {
                exact: hasInsightValue ?? convinInsights?.option_type,
            },
        });

        search_data.push({
            name: searchDataFields.sales_task__meeting__processing_status,
            model: "account.models.AccountStats",
            type: "CharField",
            search_attributes: {
                exact: "processed",
            },
        });
        search_data.push({
            name: searchDataFields.sales_task__meeting__owner__is_active,
            model: "account.models.AccountStats",
            type: "BooleanField",
            search_attributes: {
                exact: true,
            },
        });
        if (convinInsights.module === "customer_intelligence") {
            search_data.push({
                name: searchDataFields.sales_task__meeting__insight_score__is_primary,
                model: "account.models.AccountStats",
                type: "BooleanField",
                search_attributes: {
                    exact: isPrimary ?? true,
                },
            });
        }
        if (
            convinInsights.module !== "custom_tracking" &&
            !isDefined(hasInsightValue)
        ) {
            search_data.push({
                name: searchDataFields.sales_task__meeting__insight_score__insight__parent_insight__id,
                model: "account.models.AccountStats",
                type: "AutoField",
                search_attributes: {
                    isnull: true,
                },
            });
        }
    }

    return {
        order_by: accountSortBy,
        ...(accountQueryBy?.length && {
            query: accountQueryBy,
        }),
        ...(isDefined(minMaxInteractions[0]) && {
            min_interaction_count: minMaxInteractions[0],
        }),
        ...(isDefined(ai_disposition_template) &&
            dispositionScope === "account" && {
                ai_disposition_template_id: ai_disposition_template,
            }),
        ...(isDefined(level1) && {
            disposition_level_1_id: level1,
        }),
        ...(isDefined(level2) && {
            disposition_level_2_id: level2,
        }),
        ...(isDefined(level3) && {
            disposition_level_3_id: level3,
        }),

        ...(isDefined(minMaxInteractions[1]) && {
            max_interaction_count: minMaxInteractions[1],
        }),
        single_agent_accounts,
        search_data,
    };
};

export const prepareSamplingAccountSearchData = (
    filters: Partial<FiltersPayload> &
        Pick<
            DrawerFiltersType,
            "dealSize" | "minMaxInteractions" | "auditType"
        > & {
            lastContactedDate?: {
                start: null | number | string;
                end: null | number | string;
            };
            dateKey?: string;
        }
): SearchFilter[] => {
    const {
        dealSize,
        minMaxInteractions,
        lastContactedDate,
        account_tags_id,
        stages_id,
        min_duration,
        max_duration,
        start_date,
        end_date,
        auditType,
        dateKey,
        teams_id,
        reps_id,
        ...rest
    } = filters;

    const search_data = [
        ...preparePageSearchData({
            ...rest,
            dashboard: "account",
            duration: [min_duration ?? null, max_duration ?? null],
            date: [start_date ?? null, end_date ?? null],
            dateKey,
        }),
    ];

    if (isDefined(dealSize.min) || isDefined(dealSize.max))
        search_data.push({
            name: searchDataFields.account_size,
            model: searchDataModels.opportunity,
            type: searchDataTypes.PositiveIntegerField,
            search_attributes: {
                gte: dealSize.min,
                lte: dealSize.max,
            },
        });

    if (
        isDefined(lastContactedDate) &&
        (isDefined(lastContactedDate?.start) ||
            isDefined(lastContactedDate?.end))
    )
        search_data.push({
            name: searchDataFields.last_connected_date,
            model: searchDataModels.opportunity,
            type: searchDataTypes.DateField,
            search_attributes: getUTCDateSearchAtrribute(
                Object.values(lastContactedDate),
                true
            ),
        });

    if (account_tags_id?.length) {
        search_data.push({
            name: searchDataFields.sales_task__tags__id,
            model: searchDataModels.opportunity,
            type: searchDataTypes.AutoField,
            search_attributes: {
                in: account_tags_id,
            },
        });
    }

    if (isDefined(stages_id)) {
        search_data.push({
            name: searchDataFields.stage__id,
            model: searchDataModels.opportunity,
            type: searchDataTypes.AutoField,
            search_attributes: {
                in: stages_id,
            },
        });
    }

    if (isDefined(auditType)) {
        search_data.push(
            ...setAccountAuditSearchData({
                auditType,
            })
        );
    }

    if (!!minMaxInteractions && isDefined(minMaxInteractions[0])) {
        search_data.push({
            name: searchDataFields.interaction_count,
            model: searchDataModels.opportunity,
            type: searchDataTypes.BigIntegerField,
            search_attributes: {
                gte: minMaxInteractions[0],
                lte: null,
            },
        });
    }

    if (reps_id?.length) {
        search_data.push({
            name: searchDataFields.sales_task__account_score__owner__id,
            model: searchDataModels.opportunity,
            type: searchDataTypes.AutoField,
            search_attributes: {
                in: reps_id,
            },
        });
    }
    if (teams_id?.length) {
        search_data.push({
            name: searchDataFields.sales_task__account_score__owner__team__id,
            model: searchDataModels.opportunity,
            type: searchDataTypes.AutoField,
            search_attributes: {
                in: teams_id,
            },
        });
    }

    return search_data;
};

export const prepareAnalyticsSearchData = ({
    tags,
    stage,
    auditType,
    template,
    ...rest
}: Partial<GlobalFiltersStateType> & {
    duration: StaticFiltersParam["duration"];
    date?: StaticFiltersParam["date"];
}): AnalyticsParameterDetailsPayload => {
    const search_data = [
        ...preparePageSearchData({
            teams_id: rest.teams,
            reps_id: rest.reps,
            duration: rest.duration,
            ...(isDefined(rest.date) && { date: rest.date }),
        }),
    ];
    if (tags?.tagIds?.length) {
        search_data.push(setMeetingTagsSearchData(tags));
    }
    if (stage) {
        search_data.push(setStageSearchData(stage));
    }

    return {
        search_data: search_data,
        status_for: Boolean(auditType) ? "manaual" : "ai",
        ...(isDefined(template) && {
            template_id: template,
        }),
    };
};

export const prepareAccountConversationListSearchData = (
    payload: Partial<StaticFiltersParam> & { accountId: number }
): { search_data: SearchFilter[] } => {
    const search_data = [...preparePageSearchData(payload)];
    if (payload.accountId) {
        search_data.push({
            name: searchDataFields.sales_task__id,
            model: searchDataModels.meeting,
            type: searchDataTypes.AutoField,
            search_attributes: {
                exact: payload.accountId,
            },
        });
    }
    if (payload.accountId) {
        search_data.push({
            name: searchDataFields.sales_task__id,
            model: searchDataModels.meeting,
            type: searchDataTypes.AutoField,
            search_attributes: {
                exact: payload.accountId,
            },
        });
    }
    if (payload.meetingType) {
        search_data.push(setMeetingTypeSearchData(payload.meetingType));
    }

    return {
        search_data,
    };
};

export const prepareAccountConversationListQuestionSearchData = (
    payload: Pick<ScoreObject, "score_given"> & {
        accountId: number;
        questionId: number;
    }
): { search_data: SearchFilter[] } => {
    const search_data = [];
    if (payload.accountId) {
        search_data.push({
            name: searchDataFields.sales_task__id,
            model: searchDataModels.meeting,
            type: searchDataTypes.AutoField,
            search_attributes: {
                exact: payload.accountId,
            },
        });
    }
    if (payload.questionId) {
        search_data.push({
            name: searchDataFields.score__question__id,
            model: searchDataModels.meeting,
            type: searchDataTypes.AutoField,
            search_attributes: {
                exact: +payload.questionId,
            },
        });
    }
    if (isDefined(payload.score_given)) {
        search_data.push({
            name: searchDataFields.score__score_given,
            model: searchDataModels.meeting,
            type: searchDataTypes.FloatField,
            search_attributes: {
                exact: payload.score_given,
            },
        });
    }

    search_data.push({
        name: searchDataFields.score__is_ai_rated,
        model: searchDataModels.meeting,
        type: searchDataTypes.BooleanField,
        search_attributes: {
            exact: true,
        },
    });

    return {
        search_data,
    };
};

export const prepareConversationListSearchData = (
    payload: Partial<ConversationSearchDecodedFilters> & {
        dateKey?: string;
        convinInsights?: insightsFilterType;
        isPrimary?: boolean;
    }
): Array<SearchFilter> => {
    const searchData = [
        ...preparePageSearchData({
            date: [payload.startDate ?? null, payload.endDate ?? null],
            duration: [
                payload.minDuration ? payload.minDuration : null,
                payload.maxDuration ? payload.maxDuration : null,
            ],
            teams_id: payload.teams || [],
            reps_id: payload.reps || [],
            dateKey: payload.dateKey,
        }),

        ...(isDefined(payload.conversationSkills)
            ? setConversationSkillsSearchData(payload.conversationSkills)
            : []),
        ...(isDefined(payload.questions)
            ? setQuestionsSearchData(payload.questions)
            : []),
        ...(payload.searchKeywords?.keywords?.length
            ? setKeywordSearchData(payload.searchKeywords)
            : []),
        ...(payload.attribute ? prepareAttributeFilter(payload.attribute) : []),
    ] as SearchFilter[];

    const {
        auditType,
        auditStartDate,
        auditEndDate,
        auditors,
        isDraftAudit,
        auditFeedbackStatus,
        dispositionTemplate,
        convinInsights,
        isPrimary,
    } = payload;

    if (isDefined(auditType)) {
        searchData.push(
            ...setAuditSearchData({
                auditType,
                auditStartDate,
                auditEndDate,
                auditors,
            })
        );
    }

    if (isDraftAudit) {
        searchData.push(...setIsInDraftFilter(isDraftAudit));
    }
    if (isDefined(convinInsights?.option_type)) {
        searchData.push(...setInsightSearchData(convinInsights, isPrimary));
    }

    if (isDefined(payload.agentTenure)) {
        searchData.push(...setTenureBucketSearchField(payload.agentTenure));
    }

    if (isDefined(auditFeedbackStatus)) {
        searchData.push(...setFeedbackStatusFilter(auditFeedbackStatus));
    }

    if (isDefined(payload.scoresense)) {
        searchData.push(...prepareScoreSenseFilter(payload.scoresense));
    }
    if (payload.meetingType) {
        searchData.push(setMeetingTypeSearchData(payload.meetingType));
    }

    if (
        isDefined(payload.conversationScore?.[0]) ||
        isDefined(payload.conversationScore?.[1])
    ) {
        searchData.push(
            setConversationScoreSearchData(payload.conversationScore!)
        );
    }

    searchData.push(
        ...setTemplateScoreSearchData(
            payload.template ?? null,
            payload.templateCategories,
            payload.templateQuestions
        )
    );
    if (payload.client) {
        searchData.push(setMeetingSalesTaskSearchData(payload.client));
    }

    if (payload.tags?.tagIds?.length) {
        searchData.push(setMeetingTagsSearchData(payload.tags));
    }

    if (payload.callTypes?.typeIds?.length) {
        searchData.push(setCallTypesSearchData(payload.callTypes));
    }

    if (isDefined(payload.topics?.id)) {
        searchData.push(setTopicsSearchData(payload.topics!));
    }

    if (isDefined(payload.stage)) {
        searchData.push(setStageSearchData(payload.stage));
    }

    if (isDefined(payload.recordingMedium)) {
        searchData.push(setRecordingMediumSearchData(payload.recordingMedium));
    }
    if (
        isDefined(payload.processingStatus) &&
        payload.processingStatus?.length
    ) {
        searchData.push(
            setProcessingStatusSearchField(payload.processingStatus)
        );
    }

    if (isDefined(payload.startJoinTime) || isDefined(payload.endJoinTime)) {
        searchData.push(
            setRepJoiningSearchData(
                [payload.startJoinTime ?? null, payload.endJoinTime ?? null],
                payload.joiningDateKey
            )
        );
    }

    if (
        isDefined(dispositionTemplate) &&
        dispositionTemplate.disposition_scope === "meeting"
    ) {
        searchData.push(
            ...setDispositionTemplateData(
                dispositionTemplate,
                searchDataModels.meeting,
                {
                    templateName:
                        searchDataFields.ai_disposition_results__disposition__ai_disposition_template__id,
                    resultName: searchDataFields.ai_disposition_results__result,
                    dispositionName:
                        searchDataFields.ai_disposition_results__disposition__id,
                }
            )
        );
    }

    return searchData;
};
