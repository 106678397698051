import GenericSingleSelect from "@convin/components/select/GenericSingleSelect";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import {
    useGetDispositionTemplateFiltersByIdQuery,
    useGetDispositionTemplateListQuery,
} from "@convin/redux/services/settings/aiDisposition.service";
import { isDefined } from "@convin/utils/helper/common.helper";
import { Box, Stack, Typography } from "@mui/material";

export default function AiDispositionTemplateFilter() {
    const {
        state: {
            dispositionTemplate: { template, level1, level2, level3 },
        },
        updateState: updateFiltersState,
        dispositionScope,
    } = useGlobalFiltersStateContext();
    const { data, isLoading } = useGetDispositionTemplateListQuery({
        isArchive: false,
    });
    const { data: filters } = useGetDispositionTemplateFiltersByIdQuery(
        template!,
        {
            skip: !isDefined(template),
        }
    );

    return (
        <Box>
            <Typography className="font-semibold" mb={1.5}>
                AI Disposition
            </Typography>
            <GenericSingleSelect
                label="Select Disposition Template"
                autocompleteProps={{
                    options:
                        data
                            ?.filter(
                                (disposition) =>
                                    disposition.disposition_scope ===
                                    dispositionScope
                            )
                            .map((e) => ({
                                ...e,
                                label: e.name,
                            })) || [],
                    disableClearable: false,
                }}
                value={template}
                setValue={(e) => {
                    updateFiltersState({
                        dispositionTemplate: {
                            template: e,
                            level1: null,
                            level2: null,
                            level3: null,
                            disposition_scope: dispositionScope,
                        },
                    });
                }}
                className="flex-1"
                loading={isLoading}
            />
            <Typography className="font-semibold" mt={2} mb={1.5}>
                Levels
            </Typography>
            <Stack gap={1.5}>
                <GenericSingleSelect
                    label="Select Level 1"
                    value={level1}
                    autocompleteProps={{
                        options:
                            isDefined(template) &&
                            isDefined(filters) &&
                            filters.disposition_scope === dispositionScope
                                ? filters.dispositions?.map((e) => ({
                                      id: e.id!,
                                      label: e.level_1,
                                  }))
                                : [],
                        disableClearable: false,
                        disabled: !isDefined(template),
                    }}
                    setValue={(e) => {
                        updateFiltersState({
                            dispositionTemplate: {
                                template,
                                level1: e,
                                level2: null,
                                level3: null,
                                disposition_scope: dispositionScope,
                            },
                        });
                    }}
                    className="flex-1"
                    loading={isLoading}
                />
                <GenericSingleSelect
                    label="Select Level 2"
                    value={level2}
                    autocompleteProps={{
                        options:
                            isDefined(template) &&
                            isDefined(filters) &&
                            filters.disposition_scope === dispositionScope &&
                            isDefined(level1)
                                ? filters.dispositions
                                      ?.find((e) => e.id === level1)
                                      ?.children?.map((e) => ({
                                          id: e.id!,
                                          label: e.level_2,
                                      })) ?? []
                                : [],
                        disableClearable: false,
                        disabled: !isDefined(level1),
                    }}
                    setValue={(e) => {
                        updateFiltersState({
                            dispositionTemplate: {
                                template,
                                level1,
                                level2: e,
                                level3: null,
                                disposition_scope: dispositionScope,
                            },
                        });
                    }}
                    className="flex-1"
                    loading={isLoading}
                />
                <GenericSingleSelect
                    label="Select Level 3"
                    value={level3}
                    autocompleteProps={{
                        options:
                            isDefined(template) &&
                            isDefined(filters) &&
                            filters.disposition_scope === dispositionScope &&
                            isDefined(level1) &&
                            isDefined(level2)
                                ? filters.dispositions
                                      ?.find((e) => e.id === level1)
                                      ?.children?.find((e) => e.id === level2)
                                      ?.children?.map((e) => ({
                                          id: e.id!,
                                          label: e.level_3,
                                      })) ?? []
                                : [],
                        disableClearable: false,
                        disabled: !isDefined(level2),
                    }}
                    setValue={(e) => {
                        updateFiltersState({
                            dispositionTemplate: {
                                template,
                                level1,
                                level2,
                                level3: e,
                                disposition_scope: dispositionScope,
                            },
                        });
                    }}
                    className="flex-1"
                    loading={isLoading}
                />
            </Stack>
        </Box>
    );
}
