import {
    AuditType,
    DrawerFiltersType,
    Level,
} from "@convin/type/Filters.model";
import React, { useCallback } from "react";
import { DrawerFilterHeadings } from "..";
import CISaidByFilter from "../filters/CISaidByFilter";
import LevelFilter from "../filters/LevelFilter";
import AccountTagsFilter from "../filters/AccountFilters/AccountTagsFilters";
import MinimumLeadScoreFilter from "../filters/AccountFilters/MinimumLeadScoreFilter";
import MinMaxInteractions from "../filters/AccountFilters/MinimumNumberOfInteractions";
import CloseDateFilter from "../filters/AccountFilters/CloseDateFilter";
import LastContactedFilter from "../filters/AccountFilters/LastContactedFilter";
import DealSize from "../filters/AccountFilters/DealSize";
import LeadTypeFilter from "../filters/AccountFilters/LeadTypeFilter";
import {
    ClientFilter,
    ConversationScoreFilter,
    ConversationSkillsFilter,
    DraftFilter,
    KeywordSearchFilter,
    ProcessingStatusFilter,
    QuestionsFilter,
    RecordingMediumFilter,
    TemplateInfoFilter,
    TopicsFilter,
    TypesFilter,
} from "../filters/ConversationListFilters";
import AuditTypeFilter from "../filters/AuditTypeFilter";
import AuditFeedbackStatus from "../filters/ConversationListFilters/AuditFeedbackStatus";
import ScoreSenseFilter from "../filters/ConversationListFilters/ScoreSenseFilter";
import TagsFilter from "../filters/TagsFilter";
import StageFilter from "../filters/StageFilter";
import { useGetCallTagsQuery } from "@convin/redux/services/settings/callTagsManager.service";
import { useGetCallTypesQuery } from "@convin/redux/services/settings/callTypeManager.service";
import {
    useGetAccountTagsQuery,
    useGetStagesQuery,
} from "@convin/redux/services/account/account.service";
import useGlobalFiltersStateContext from "@convin/hooks/useGlobalFiltersStateContext";
import {
    GlobalFiltersInitialState,
    GlobalFiltersStateType,
} from "@convin/contexts/GlobalFiltersStateContextProvider";
import {
    draftStatusOptions,
    processingStatusOptions,
} from "@convin/config/default.config";
import { useGetConferenceToolsQuery } from "@convin/redux/services/meeting/filters.service";
import {
    ciInsightsOptions,
    flattenInsights,
    flattenTeams,
    getMinMaxLabel,
    getUserName,
    isDefined,
    modulesOptions,
} from "@convin/utils/helper/common.helper";
import { useGetTeamsQuery } from "@convin/redux/services/settings/teamManager.service";
import {
    useGetAllAuditorsQuery,
    useGetRestrictedUsersQuery,
} from "@convin/redux/services/settings/users.service";
import { IFilterVisibility } from "@convin/contexts/FiltersVisibilityContextProvider";
import { useGetTemplateInfoByIdQuery } from "@convin/redux/services/settings/auditManager.service";
import { conversationSkillsLabelMap } from "@convin/config/conversation.config";
import "@total-typescript/ts-reset";
import isEqual from "lodash.isequal";
import { useGetDomainConfigQuery } from "@convin/redux/services/domain/domain.service";
import useGlobalSearchContext from "@convin/modules/GlobalDashboardLayout/hooks/useGlobalSearchContext";
import { useAccountFilterCheck } from "@convin/hooks/useAccountFilterCheck";
import AttributeFilter from "../filters/ConversationListFilters/AttributeFilter";
import {
    useGetAttributeCategoriesQuery,
    useGetAttributeCategoryKeysQuery,
} from "@convin/redux/services/settings/auditAiConfig.service";
import AiDispositionTemplateFilter from "../filters/ConversationListFilters/AiDispositionTemplateFilter";
import { useGetDispositionTemplateFiltersByIdQuery } from "@convin/redux/services/settings/aiDisposition.service";
import AppliedFilters from "../../AppliedFilters";
import AuditorFilter from "../filters/AuditorFilter";
import { AgentTenureFilter } from "../filters/AgentTenureFilter";
import ConvinInsightsFilter from "../filters/ConvinInsightsFilter";
import AccountTeamsFilter from "../filters/AccountTeamsFilter";
import AccountRepsFilter from "../filters/AccountRepsFilter";
import {
    useGetAllInsightsByTypeQuery,
    useGetCITabQuery,
    useGetCITabsQuery,
} from "@convin/redux/services/customerIntelligence/customerIntelligence.service";
import { useGetCustomAiFeedbackQuesByIdQuery } from "@convin/redux/services/settings/aiFeedback.service";
import {
    CustomTrackingTab,
    InsightDetail,
    InsightType,
} from "@convin/type/customerIntelligence";
import useCIContext from "@convin/modules/customerInteligence/modules/CustomTracking/hooks/useCIContext";
import { useGetTopicsQuery } from "@convin/redux/services/settings/topicManager.service";
import MeetingDateFilter from "../filters/MeetingDateFilter";

type AppliedFilters = {
    label?: string;
    value: string | number;
    onRemove: () => void;
};

export type UseAppliedFiltersReturnType = {
    filtersList: {
        heading: string;
        Component: React.FC;
        filterKey: keyof DrawerFiltersType;
    }[];
    appliedFilters: AppliedFilters[];
};

const minMaxInteractionsFilter = () => <MinMaxInteractions maxLimit={100} />;

export default function useAppliedFilters(
    appliedFilterKeys: IFilterVisibility["appliedFilterKeys"],
    dashboardType: IFilterVisibility["dashboardType"]
): UseAppliedFiltersReturnType {
    const { handleSearchKeyword, searchKeyword, searchIsDirty } =
        useGlobalSearchContext();
    const { updateState, state, handleAppliedButtonClick, dispositionScope } =
        useGlobalFiltersStateContext();
    const { payload } = useCIContext();
    const { data: teams } = useGetTeamsQuery(undefined, {
        skip: !(
            appliedFilterKeys.includes("teams") ||
            appliedFilterKeys.includes("accountTeams")
        ),
    });
    const { data: users } = useGetRestrictedUsersQuery(undefined, {
        skip: !(
            appliedFilterKeys.includes("reps") ||
            appliedFilterKeys.includes("accountReps")
        ),
    });

    const { data: tags } = useGetCallTagsQuery(undefined, {
        skip: !appliedFilterKeys.includes("tags"),
    });

    const { data: type } = useGetCallTypesQuery(undefined, {
        skip: !appliedFilterKeys.includes("callTypes"),
    });
    const { data: stages } = useGetStagesQuery(undefined, {
        skip: !appliedFilterKeys.includes("stage"),
    });
    const { data: recordingMediumList } = useGetConferenceToolsQuery(
        undefined,
        {
            skip: !appliedFilterKeys.includes("recordingMedium"),
        }
    );
    const { data: accountTags } = useGetAccountTagsQuery(undefined, {
        skip: !appliedFilterKeys.includes("accountTags"),
    });
    const { data: auditorsList } = useGetAllAuditorsQuery(undefined, {
        skip: !appliedFilterKeys.includes("auditors"),
    });
    const { data: topics } = useGetTopicsQuery(undefined, {
        skip: !appliedFilterKeys.includes("topics"),
    });
    const { data: domainConfig } = useGetDomainConfigQuery();
    const { data: categories } = useGetAttributeCategoriesQuery(undefined, {
        skip: !appliedFilterKeys.includes("attribute"),
    });
    const { data: selectedtype } = useGetAttributeCategoryKeysQuery(
        {
            id: Number(state.attribute.type),
        },
        {
            skip: !isDefined(state.attribute.type),
        }
    );

    const { data: allInsightsData } = useGetAllInsightsByTypeQuery(
        {
            type: state.convinInsights.option_type as number | InsightType,
            dashboardType:
                state.convinInsights.module === "customer_intelligence"
                    ? "convin_insights"
                    : "ai_insights",
        },
        {
            skip:
                !isDefined(state.convinInsights.module) ||
                state.convinInsights.module === "custom_tracking" ||
                !isDefined(state.convinInsights.option_type),
        }
    );
    const { data: CustomTrackingTabsData } = useGetCITabsQuery(undefined, {
        skip:
            !isDefined(state.convinInsights.module) ||
            state.convinInsights.module !== "custom_tracking",
    });
    const { data: tabData } = useGetCITabQuery(
        {
            id: state.convinInsights.option_type as number,
            payload,
        },
        {
            skip:
                !isDefined(state.convinInsights.module) ||
                state.convinInsights.module !== "custom_tracking" ||
                !isDefined(state.convinInsights.option_type),
        }
    );
    const insightsData: InsightDetail[] = flattenInsights(
        allInsightsData?.insights ?? []
    );

    const { data: aiInsightsData } = useGetCustomAiFeedbackQuesByIdQuery(
        state.convinInsights.option_type as number,
        {
            skip:
                !isDefined(state.convinInsights.module) ||
                state.convinInsights.module !== "ai_insights" ||
                !isDefined(state.convinInsights.option_type),
        }
    );
    const { data: dispositionTemplateData } =
        useGetDispositionTemplateFiltersByIdQuery(
            state.dispositionTemplate.template!,
            {
                skip: !isDefined(state.dispositionTemplate.template),
            }
        );

    const isScoreSenseVisible =
        domainConfig?.feature_access.score_sense?.visibility ?? false;

    const { data: activeTemplateData } = useGetTemplateInfoByIdQuery(
        { template_id: Number(state?.template), questions: "all" },
        {
            skip: !isDefined(state?.template),
        }
    );

    const accountCheck = useAccountFilterCheck();

    const allTeams = flattenTeams(teams ?? []);

    const allTemplateQuestions =
        activeTemplateData?.categories.map((e) => e.questions).flat() ?? [];

    const {
        scoresense: { lead, csat, collection },
        dealSize,
        dispositionTemplate: { template, level1, level2, level3 },
    } = state;

    const removeFilter: typeof updateState = useCallback((e) => {
        updateState(e);
        handleAppliedButtonClick();
    }, []);

    const setTags = new Set(state.tags.tagIds);
    const setReps =
        dashboardType === "Account"
            ? new Set(state.accountReps)
            : new Set(state.reps);

    const getAuditDateFilter: () => AppliedFilters | boolean = () => {
        return (
            isDefined(state.auditDateKey) &&
            state.auditDateKey !== GlobalFiltersInitialState.auditDateKey && {
                label: "Audited Date",
                value: state.auditDateOptions[state.auditDateKey!].name,
                onRemove: () => {
                    removeFilter({
                        auditDateKey: GlobalFiltersInitialState.auditDateKey,
                    });
                },
            }
        );
    };

    const getOptionTypeLabel = (
        module: string,
        option_type: string | number
    ) => {
        if (module === "custom_tracking") {
            const tab: CustomTrackingTab | undefined =
                CustomTrackingTabsData?.find((tab) => tab.id === option_type);
            return tab?.title;
        }
        if (module === "customer_intelligence") {
            const option = ciInsightsOptions.find(
                (insight) => insight.id === option_type
            );
            return option?.label;
        }
        if (module === "ai_insights") {
            return aiInsightsData?.id === option_type
                ? aiInsightsData.question_text
                : undefined;
        }
    };

    const getAuditorsFilter: () => Array<AppliedFilters> = () => {
        return auditorsList?.length === state?.auditors?.length
            ? [
                  {
                      label: "Auditor",
                      value: "All",
                      onRemove: () => {
                          removeFilter({
                              auditors: [],
                          });
                      },
                  },
              ]
            : (auditorsList ?? [])
                  ?.filter((e) => state.auditors.includes(e.id))
                  .map((e) => ({
                      label: "Auditor",
                      value: e.label,
                      onRemove: () => {
                          removeFilter({
                              auditors: state.auditors.filter(
                                  (id) => id !== e.id
                              ),
                          });
                      },
                  }));
    };

    const filters: Array<{
        heading: string;
        Component: React.FC;
        filterKey: keyof DrawerFiltersType;
        appliedFilters?: Array<AppliedFilters>;
    }> = [
        ...(accountCheck
            ? [
                  {
                      heading: DrawerFilterHeadings.teams,
                      Component: AccountTeamsFilter,
                      filterKey: "teams",
                      appliedFilters:
                          !!state.teams?.length &&
                          appliedFilterKeys.includes("teams")
                              ? allTeams
                                    .filter((team) =>
                                        state.teams.includes(team.id)
                                    )
                                    .map((team) => ({
                                        label: "Teams",
                                        value: team.name,
                                        onRemove: () => {
                                            removeFilter({
                                                teams: state.teams.filter(
                                                    (id) => id !== team.id
                                                ),
                                            });
                                        },
                                    }))
                              : [],
                  },
                  {
                      heading: DrawerFilterHeadings.reps,
                      Component: AccountRepsFilter,
                      filterKey: "reps",
                      appliedFilters:
                          !!state.reps?.length &&
                          appliedFilterKeys.includes("reps")
                              ? (users ?? [])
                                    .filter((user) =>
                                        state.reps.includes(user.id)
                                    )
                                    .map((user) => ({
                                        label: "Reps",
                                        value: getUserName(user),
                                        onRemove: () => {
                                            removeFilter({
                                                reps: state.reps.filter(
                                                    (id) => id !== user.id
                                                ),
                                            });
                                        },
                                    }))
                              : [],
                  },
              ]
            : []),
        {
            heading: DrawerFilterHeadings.ciFilter,
            Component: CISaidByFilter,
            filterKey: "ciFilter",
            appliedFilters: [
                ...(!(
                    state.ciFilter.saidBy.client && state.ciFilter.saidBy.owner
                )
                    ? [
                          {
                              value:
                                  state.ciFilter.saidBy.client ===
                                  state.ciFilter.saidBy.owner
                                      ? "Rep and Client"
                                      : state.ciFilter.saidBy.client
                                      ? "Rep"
                                      : "Client",
                              onRemove: () => {
                                  removeFilter({
                                      ciFilter: {
                                          ...state.ciFilter,
                                          saidBy: {
                                              client: GlobalFiltersInitialState
                                                  .ciFilter.saidBy.client,
                                              owner: GlobalFiltersInitialState
                                                  .ciFilter.saidBy.owner,
                                          },
                                      },
                                  });
                              },
                          },
                      ]
                    : []),
                ...(state.ciFilter.exclude
                    ? [
                          {
                              label: `${DrawerFilterHeadings.ciFilter} - Exclude`,
                              value: state.ciFilter.exclude ? "Yes" : "No",
                              onRemove: () => {
                                  removeFilter({
                                      ciFilter: {
                                          ...state.ciFilter,
                                          exclude:
                                              GlobalFiltersInitialState.ciFilter
                                                  .exclude,
                                      },
                                  });
                              },
                          },
                      ]
                    : []),
            ],
        },
        {
            heading: DrawerFilterHeadings.level,
            Component: LevelFilter,
            filterKey: "level",
            appliedFilters:
                state.level !== Level.conversation
                    ? [
                          {
                              value: Level[Level.account].toLocaleUpperCase(),
                              onRemove: () => {
                                  removeFilter({
                                      level: GlobalFiltersInitialState.level,
                                  });
                              },
                          },
                      ]
                    : [],
        },
        {
            heading: DrawerFilterHeadings.convinInsights,
            Component: ConvinInsightsFilter,
            filterKey: "convinInsights",
            appliedFilters: isDefined(state.convinInsights.option_type)
                ? [
                      {
                          label: "Module",
                          value: modulesOptions.find(
                              (module) =>
                                  module.id === state.convinInsights.module
                          )?.label,
                          onRemove: () => {
                              removeFilter({
                                  convinInsights: {
                                      module: null,
                                      option_type: null,
                                      insights: {
                                          level1: null,
                                          level2: null,
                                          level3: null,
                                      },
                                  },
                              });
                          },
                      },
                      {
                          label:
                              state.convinInsights.module === "custom_tracking"
                                  ? "Groups"
                                  : "Insights",
                          value: getOptionTypeLabel(
                              state.convinInsights.module!,
                              state.convinInsights.option_type!
                          ),
                          onRemove: () => {
                              removeFilter({
                                  convinInsights: {
                                      ...state.convinInsights,
                                      option_type: null,
                                      insights: {
                                          level1: null,
                                          level2: null,
                                          level3: null,
                                      },
                                  },
                              });
                          },
                      },
                      isDefined(state.convinInsights.insights.level1) && {
                          label:
                              state.convinInsights.module === "custom_tracking"
                                  ? "Keyword"
                                  : "Level 1",
                          value:
                              state.convinInsights.module === "custom_tracking"
                                  ? tabData?.search_phrases.find(
                                        (phrase) =>
                                            phrase.id ===
                                            state.convinInsights.insights.level1
                                    )?.representation
                                  : insightsData.find(
                                        (insight) =>
                                            insight.id ===
                                            state.convinInsights.insights.level1
                                    )?.name,
                          onRemove: () => {
                              removeFilter({
                                  convinInsights: {
                                      ...state.convinInsights,
                                      insights: {
                                          ...state.convinInsights.insights,
                                          level1: null,
                                          level2: null,
                                          level3: null,
                                      },
                                  },
                              });
                          },
                      },
                      isDefined(state.convinInsights.insights.level2) && {
                          label: "Level 2",
                          value: insightsData.find(
                              (insight) =>
                                  insight.id ===
                                  state.convinInsights.insights.level2
                          )?.name,
                          onRemove: () => {
                              removeFilter({
                                  convinInsights: {
                                      ...state.convinInsights,
                                      insights: {
                                          ...state.convinInsights.insights,
                                          level2: null,
                                          level3: null,
                                      },
                                  },
                              });
                          },
                      },
                      isDefined(state.convinInsights.insights.level3) && {
                          label: "Level 3",
                          value: insightsData.find(
                              (insight) =>
                                  insight.id ===
                                  state.convinInsights.insights.level3
                          )?.name,
                          onRemove: () => {
                              removeFilter({
                                  convinInsights: {
                                      ...state.convinInsights,
                                      insights: {
                                          ...state.convinInsights.insights,
                                          level3: null,
                                      },
                                  },
                              });
                          },
                      },
                  ].filter(Boolean)
                : [],
        },

        {
            heading: DrawerFilterHeadings.accountTags,
            Component: AccountTagsFilter,
            filterKey: "accountTags",
            appliedFilters:
                accountTags?.length === state.accountTags.tagIds.length
                    ? [
                          {
                              value: "All",
                              onRemove: () => {
                                  removeFilter({
                                      accountTags:
                                          GlobalFiltersInitialState.accountTags,
                                  });
                              },
                          },
                      ]
                    : (accountTags ?? [])
                          .filter((e) =>
                              state.accountTags.tagIds.includes(e.id)
                          )
                          .map((e) => ({
                              value: e.label,
                              onRemove: () => {
                                  removeFilter({
                                      accountTags: {
                                          tagIds: state.accountTags.tagIds.filter(
                                              (id) => id !== e.id
                                          ),
                                          logic: state.accountTags.logic,
                                          negate: state.accountTags.negate,
                                      },
                                  });
                              },
                          })),
        },
        {
            heading: DrawerFilterHeadings.minLeadScore,
            Component: MinimumLeadScoreFilter,
            filterKey: "minLeadScore",
            appliedFilters: [
                isDefined(state.minLeadScore) && {
                    value: `${state.minLeadScore}%`,
                    onRemove: () => {
                        removeFilter({
                            minLeadScore:
                                GlobalFiltersInitialState.minLeadScore,
                        });
                    },
                },
            ].filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.minMaxInteractions,
            Component: minMaxInteractionsFilter,
            filterKey: "minMaxInteractions",
            appliedFilters: [
                (isDefined(state.minMaxInteractions?.[0]) ||
                    isDefined(state.minMaxInteractions?.[1])) && {
                    value: getMinMaxLabel({
                        min: state.minMaxInteractions?.[0],
                        max: state.minMaxInteractions?.[1],
                    }),
                    onRemove: () => {
                        removeFilter({
                            minMaxInteractions:
                                GlobalFiltersInitialState.minMaxInteractions,
                        });
                    },
                },
            ].filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.closeDateKey,
            Component: CloseDateFilter,
            filterKey: "closeDateKey",
            appliedFilters: [
                isDefined(state.closeDateKey) && {
                    value: `${state.closeDateOptions[state.closeDateKey].name}`,
                    onRemove: () => {
                        removeFilter({
                            closeDateKey:
                                GlobalFiltersInitialState.closeDateKey,
                        });
                    },
                },
            ].filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.lastContactedDateKey,
            Component: LastContactedFilter,
            filterKey: "lastContactedDateKey",
            appliedFilters: [
                isDefined(state.lastContactedDateKey) && {
                    value: `${
                        state.lastContactedDateOptions[
                            state.lastContactedDateKey
                        ].name
                    }`,
                    onRemove: () => {
                        removeFilter({
                            lastContactedDateKey:
                                GlobalFiltersInitialState.lastContactedDateKey,
                        });
                    },
                },
            ].filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.dealSize,
            Component: DealSize,
            filterKey: "dealSize",
            appliedFilters: [
                (isDefined(dealSize.min) || isDefined(dealSize.max)) && {
                    value: getMinMaxLabel({ ...dealSize, unit: "" }),
                    onRemove: () => {
                        removeFilter({
                            dealSize: GlobalFiltersInitialState.dealSize,
                        });
                    },
                },
            ].filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.leadType,
            Component: LeadTypeFilter,
            filterKey: "leadType",
            appliedFilters: [
                isDefined(state.leadType) && {
                    value: state.leadType,
                    onRemove: () => {
                        removeFilter({
                            leadType: GlobalFiltersInitialState.leadType,
                        });
                    },
                },
            ].filter(Boolean),
        },
        {
            heading: accountCheck
                ? DrawerFilterHeadings.accountScore
                : DrawerFilterHeadings.conversationScore,
            Component: ConversationScoreFilter,
            filterKey: "conversationScore",
            appliedFilters: [
                (isDefined(state.conversationScore?.[0]) ||
                    isDefined(state.conversationScore?.[1])) && {
                    value: getMinMaxLabel({
                        min: state.conversationScore?.[0],
                        max: state.conversationScore?.[1],
                    }),
                    onRemove: () => {
                        removeFilter({
                            conversationScore:
                                GlobalFiltersInitialState.conversationScore,
                        });
                    },
                },
            ].filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.templateCategories,
            Component: TemplateInfoFilter,
            filterKey: "template",
            appliedFilters:
                isDefined(state.template) && isDefined(activeTemplateData)
                    ? [
                          {
                              label: "Template",
                              value: activeTemplateData.template.name,
                              onRemove: () => {
                                  removeFilter({
                                      template: null,
                                      templateCategories:
                                          GlobalFiltersInitialState.templateCategories,
                                      templateQuestions:
                                          GlobalFiltersInitialState.templateQuestions,
                                  });
                              },
                          },
                          ...Object.keys(state.templateCategories)
                              .map((id) => {
                                  const category =
                                      activeTemplateData.categories.find(
                                          (c) => c.id === +id
                                      );
                                  const categoryFilter = {
                                      ...state.templateCategories,
                                  };
                                  delete categoryFilter[+id];
                                  return category
                                      ? {
                                            label: category.name,
                                            value: getMinMaxLabel({
                                                min: state.templateCategories[
                                                    +id
                                                ][0],
                                                max: state.templateCategories[
                                                    +id
                                                ][1],
                                                unit: "%",
                                            }),
                                            onRemove: () => {
                                                removeFilter({
                                                    templateCategories:
                                                        categoryFilter,
                                                });
                                            },
                                        }
                                      : undefined;
                              })
                              .filter(Boolean)
                              .filter((e) => e.value),
                          ...Object.keys(state.templateQuestions)
                              .map((id) => {
                                  const question = allTemplateQuestions.find(
                                      (q) => q.id === +id
                                  );
                                  const questionFilter = {
                                      ...state.templateQuestions,
                                  };
                                  delete questionFilter[+id];
                                  return question
                                      ? {
                                            label: question.question_text,
                                            value:
                                                question.question_type ===
                                                "yes_no"
                                                    ? ["No", "Yes"].find(
                                                          (_, idx) =>
                                                              idx ===
                                                              state
                                                                  .templateQuestions[
                                                                  +id
                                                              ]
                                                      ) ?? "Na"
                                                    : question.question_type ===
                                                      "custom"
                                                    ? question.settings.custom.find(
                                                          (e) =>
                                                              e.id ===
                                                              state
                                                                  .templateQuestions[
                                                                  +id
                                                              ]
                                                      )?.name
                                                    : state.templateQuestions[
                                                          +id
                                                      ],
                                            onRemove: () => {
                                                removeFilter({
                                                    templateQuestions:
                                                        questionFilter,
                                                });
                                            },
                                        }
                                      : undefined;
                              })
                              .filter(Boolean),
                      ].filter(Boolean)
                    : [],
        },
        {
            heading: DrawerFilterHeadings.agentTenure,
            Component: AgentTenureFilter,
            filterKey: "agentTenure",
            appliedFilters: state.agentTenure
                ? [
                      {
                          label: "Agent Tenure Buckets",
                          value: getMinMaxLabel({
                              min: state.agentTenure.start,
                              max: state.agentTenure.end,
                          }),
                          onRemove: () => {
                              removeFilter({
                                  agentTenure: null,
                              });
                          },
                      },
                  ]
                : [],
        },
        {
            heading: DrawerFilterHeadings.auditType,
            Component: AuditTypeFilter,
            filterKey: "auditType",
            appliedFilters: [
                isDefined(state.auditType) && {
                    value: state.auditType ? "Manual" : "AI",
                    onRemove: () => {
                        removeFilter({
                            auditType: GlobalFiltersInitialState.auditType,
                            //If manual clear mnual related filters
                            ...(!!state.auditType && {
                                auditors: GlobalFiltersInitialState.auditors,
                                auditDateKey:
                                    GlobalFiltersInitialState.auditDateKey,
                                isDraftAudit:
                                    GlobalFiltersInitialState.isDraftAudit,
                            }),
                        });
                    },
                },
                //If Manual check the audit date filter
                isDefined(state.auditType) &&
                    state.auditType === AuditType.manual &&
                    getAuditDateFilter(),
                //If Manual check the auditors filter
                ...(isDefined(state.auditType) &&
                state.auditType === AuditType.manual
                    ? getAuditorsFilter()
                    : []),
            ].filter(Boolean),
        },

        {
            heading: DrawerFilterHeadings.auditors,
            Component: AuditorFilter,
            filterKey: "auditors",
            appliedFilters: [
                getAuditDateFilter(),
                ...getAuditorsFilter(),
            ].filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.dateKey,
            Component: MeetingDateFilter,
            filterKey: "dateKey",
        },
        {
            heading: DrawerFilterHeadings.isDraftAudit,
            Component: DraftFilter,
            filterKey: "isDraftAudit",
            appliedFilters: [
                state.isDraftAudit && {
                    value: "Audit - Draft",
                    onRemove: () => {
                        removeFilter({
                            isDraftAudit:
                                GlobalFiltersInitialState.isDraftAudit,
                        });
                    },
                },
            ].filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.auditFeedbackStatus,
            Component: AuditFeedbackStatus,
            filterKey: "auditFeedbackStatus",
            appliedFilters: draftStatusOptions
                .filter((e) => e.id === state.auditFeedbackStatus)
                .map((e) => ({
                    value: e.value,
                    onRemove: () => {
                        removeFilter({
                            auditFeedbackStatus:
                                GlobalFiltersInitialState.auditFeedbackStatus,
                        });
                    },
                })),
        },
        {
            heading: DrawerFilterHeadings.client,
            Component: ClientFilter,
            filterKey: "client",
            appliedFilters: isDefined(state.client)
                ? [
                      {
                          value: state.client, // TODO: Get client details api and fill label instead of id
                          onRemove: () => {
                              removeFilter({
                                  client: GlobalFiltersInitialState.client,
                              });
                          },
                      },
                  ]
                : [],
        },
        isScoreSenseVisible && {
            heading: DrawerFilterHeadings.scoresense,
            Component: ScoreSenseFilter,
            filterKey: "scoresense",
            appliedFilters: [
                isDefined(lead) && {
                    value: lead,
                    onRemove: () => {
                        removeFilter({
                            scoresense: {
                                ...state.scoresense,
                                lead: GlobalFiltersInitialState.scoresense.lead,
                            },
                        });
                    },
                },
                isDefined(csat) && {
                    value: csat,
                    onRemove: () => {
                        removeFilter({
                            scoresense: {
                                ...state.scoresense,
                                csat: GlobalFiltersInitialState.scoresense.csat,
                            },
                        });
                    },
                },
                isDefined(collection) && {
                    value: collection,
                    onRemove: () => {
                        removeFilter({
                            scoresense: {
                                ...state.scoresense,
                                collection:
                                    GlobalFiltersInitialState.scoresense
                                        .collection,
                            },
                        });
                    },
                },
            ]?.filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.searchKeywords,
            Component: KeywordSearchFilter,
            filterKey: "searchKeywords",
            appliedFilters:
                isDefined(state.searchKeywords) &&
                state.searchKeywords.keywords?.length
                    ? [
                          ...state.searchKeywords.keywords.map(
                              (keyword: string) => ({
                                  value: keyword,
                                  onRemove: () => {
                                      if (searchKeyword === keyword) {
                                          handleSearchKeyword("");
                                          searchIsDirty.current = false;
                                      }
                                      removeFilter({
                                          searchKeywords: {
                                              ...state.searchKeywords,
                                              keywords:
                                                  state.searchKeywords.keywords.filter(
                                                      (k: string) =>
                                                          k !== keyword
                                                  ),
                                              ...(state.searchKeywords.keywords
                                                  ?.length === 1
                                                  ? {
                                                        saidByClient:
                                                            GlobalFiltersInitialState
                                                                .searchKeywords
                                                                .saidByClient,
                                                        saidByOwner:
                                                            GlobalFiltersInitialState
                                                                .searchKeywords
                                                                .saidByOwner,
                                                        isInCall:
                                                            GlobalFiltersInitialState
                                                                .searchKeywords
                                                                .isInCall,
                                                    }
                                                  : {}),
                                          },
                                      });
                                  },
                              })
                          ),
                          (state.searchKeywords.saidByClient ||
                              state.searchKeywords.saidByOwner) && {
                              label: `${DrawerFilterHeadings.searchKeywords} - Said By`,
                              value:
                                  state.searchKeywords.saidByClient ===
                                  state.searchKeywords.saidByOwner
                                      ? "Rep & Others"
                                      : state.searchKeywords.saidByClient
                                      ? "Others"
                                      : "Rep",
                              onRemove: () => {
                                  removeFilter({
                                      searchKeywords: {
                                          ...state.searchKeywords,
                                          saidByClient:
                                              GlobalFiltersInitialState
                                                  .searchKeywords.saidByClient,
                                          saidByOwner:
                                              GlobalFiltersInitialState
                                                  .searchKeywords.saidByOwner,
                                      },
                                  });
                              },
                          },
                          !state.searchKeywords.isInCall && {
                              label: `${DrawerFilterHeadings.searchKeywords} - Present In Conversation`,
                              value: state.searchKeywords.isInCall
                                  ? "Yes"
                                  : "No",
                              onRemove: () => {
                                  removeFilter({
                                      searchKeywords: {
                                          ...state.searchKeywords,
                                          isInCall:
                                              GlobalFiltersInitialState
                                                  .searchKeywords.isInCall,
                                      },
                                  });
                              },
                          },
                      ].filter(Boolean)
                    : [],
        },
        {
            heading: DrawerFilterHeadings.tags,
            Component: TagsFilter,
            filterKey: "tags",
            appliedFilters:
                tags?.length === state.tags.tagIds.length
                    ? [
                          {
                              value: "All",
                              onRemove: () => {
                                  removeFilter({
                                      tags: GlobalFiltersInitialState.tags,
                                  });
                              },
                          },
                      ]
                    : (tags ?? [])
                          .filter((e) => setTags.has(e.id))
                          .map((e) => ({
                              value: e.label,
                              onRemove: () => {
                                  removeFilter({
                                      tags: {
                                          tagIds: state.tags.tagIds.filter(
                                              (id) => id !== e.id
                                          ),
                                          logic: state.tags.logic,
                                          negate: state.tags.negate,
                                      },
                                  });
                              },
                          })),
        },
        {
            heading: DrawerFilterHeadings.callTypes,
            Component: TypesFilter,
            filterKey: "callTypes",
            appliedFilters:
                type?.length === state.callTypes.typeIds.length
                    ? [
                          {
                              value: "All",
                              onRemove: () => {
                                  removeFilter({
                                      callTypes:
                                          GlobalFiltersInitialState.callTypes,
                                  });
                              },
                          },
                      ]
                    : (type ?? [])
                          .filter((e) => state.callTypes.typeIds.includes(e.id))
                          .map((e) => ({
                              value: e.label,
                              onRemove: () => {
                                  removeFilter({
                                      callTypes: {
                                          typeIds:
                                              state.callTypes.typeIds.filter(
                                                  (id) => id !== e.id
                                              ),
                                          logic: state.callTypes.logic,
                                          negate: state.callTypes.negate,
                                      },
                                  });
                              },
                          })),
        },
        {
            heading: DrawerFilterHeadings.questions,
            Component: QuestionsFilter,
            filterKey: "questions",
            appliedFilters: Object.entries(state.questions)
                .filter(
                    ([, value]) =>
                        isDefined(value?.[0]) || isDefined(value?.[1])
                )
                .map(([key, value]) => ({
                    label: `${DrawerFilterHeadings.questions} - ${
                        key === "byClient" ? "By Client" : "By Rep"
                    }`,
                    value: getMinMaxLabel({
                        min: value?.[0],
                        max: value?.[1],
                    }),
                    onRemove: () => {
                        removeFilter({
                            questions: {
                                ...state.questions,
                                [key]: GlobalFiltersInitialState.questions[
                                    key as keyof GlobalFiltersStateType["questions"]
                                ],
                            },
                        });
                    },
                }))
                .filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.topics,
            Component: TopicsFilter,
            filterKey: "topics",
            appliedFilters: isDefined(state.topics.id)
                ? [
                      {
                          value:
                              (topics ?? []).find(
                                  (topic) => topic.id === state.topics.id
                              )?.name ?? state.topics.id,
                          onRemove: () => {
                              removeFilter({
                                  topics: GlobalFiltersInitialState.topics,
                              });
                          },
                      },
                      !state.topics.inCall && {
                          label: `${DrawerFilterHeadings.topics} - Present In Conversation`,
                          value: "No",
                          onRemove: () => {
                              removeFilter({
                                  topics: {
                                      ...state.topics,
                                      inCall: GlobalFiltersInitialState.topics
                                          .inCall,
                                  },
                              });
                          },
                      },
                  ].filter(Boolean)
                : [],
        },
        {
            heading: DrawerFilterHeadings.conversationSkills,
            Component: ConversationSkillsFilter,
            filterKey: "conversationSkills",
            appliedFilters: Object.entries(state.conversationSkills)
                .filter(
                    ([key]) =>
                        !isEqual(
                            state.conversationSkills[
                                key as keyof typeof state.conversationSkills
                            ],
                            GlobalFiltersInitialState.conversationSkills[
                                key as keyof typeof GlobalFiltersInitialState.conversationSkills
                            ]
                        )
                )
                .map(([key, value]) => ({
                    label: conversationSkillsLabelMap[
                        key as keyof GlobalFiltersStateType["conversationSkills"]
                    ].label,
                    value: getMinMaxLabel({
                        min: (value as Array<number>)?.[0],
                        max: (value as Array<number>)?.[1],
                        unit: conversationSkillsLabelMap[
                            key as keyof GlobalFiltersStateType["conversationSkills"]
                        ].unit,
                    }),
                    onRemove: () => {
                        removeFilter({
                            conversationSkills: {
                                ...state.conversationSkills,
                                [key]: GlobalFiltersInitialState
                                    .conversationSkills[
                                    key as keyof GlobalFiltersStateType["conversationSkills"]
                                ],
                            },
                        });
                    },
                }))
                .filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.recordingMedium,
            Component: RecordingMediumFilter,
            filterKey: "recordingMedium",
            appliedFilters: (recordingMediumList ?? [])
                .filter((e) => e === state.recordingMedium)
                .map((e) => ({
                    value: e,
                    onRemove: () => {
                        removeFilter({
                            recordingMedium:
                                GlobalFiltersInitialState.recordingMedium,
                        });
                    },
                })),
        },
        {
            heading: DrawerFilterHeadings.stage,
            Component: StageFilter,
            filterKey: "stage",
            appliedFilters: (stages ?? [])
                .filter((e) => e.id === state.stage)
                .map((e) => ({
                    value: e.label,
                    onRemove: () => {
                        removeFilter({
                            stage: GlobalFiltersInitialState.stage,
                        });
                    },
                })),
        },
        {
            heading: DrawerFilterHeadings.dispositionTemplate,
            Component: AiDispositionTemplateFilter,
            filterKey: "dispositionTemplate",
            appliedFilters:
                isDefined(template) &&
                isDefined(dispositionTemplateData) &&
                dispositionTemplateData.disposition_scope === dispositionScope
                    ? [
                          {
                              label: "Disposition Template",
                              value: dispositionTemplateData?.name,
                              onRemove: () => {
                                  removeFilter({
                                      dispositionTemplate:
                                          GlobalFiltersInitialState.dispositionTemplate,
                                  });
                              },
                          },
                          isDefined(level1) && {
                              label: "Disposition Level 1",
                              value: dispositionTemplateData?.dispositions.find(
                                  (e) => e.id === level1
                              )?.level_1,
                              onRemove: () => {
                                  removeFilter({
                                      dispositionTemplate: {
                                          template,
                                          level1: null,
                                          level2: null,
                                          level3: null,
                                          disposition_scope: dispositionScope,
                                      },
                                  });
                              },
                          },

                          isDefined(level2) && {
                              label: "Disposition Level 2",
                              value: dispositionTemplateData?.dispositions
                                  ?.find((e) => e.id === level1)
                                  ?.children?.find((e) => e.id === level2)
                                  ?.level_2,
                              onRemove: () => {
                                  removeFilter({
                                      dispositionTemplate: {
                                          template,
                                          level1,
                                          level2: null,
                                          level3: null,
                                          disposition_scope: dispositionScope,
                                      },
                                  });
                              },
                          },

                          isDefined(level3) && {
                              label: "Disposition Level 3",
                              value: dispositionTemplateData?.dispositions
                                  ?.find((e) => e.id === level1)
                                  ?.children.find((e) => e.id === level2)
                                  ?.children.find((e) => e.id === level3)
                                  ?.level_3,
                              onRemove: () => {
                                  removeFilter({
                                      dispositionTemplate: {
                                          ...state.dispositionTemplate,
                                          level3: null,
                                      },
                                  });
                              },
                          },
                      ].filter(Boolean)
                    : [],
        },

        {
            heading: DrawerFilterHeadings.attribute,
            Component: AttributeFilter,
            filterKey: "attribute",
            appliedFilters: [
                isDefined(state.attribute.category) &&
                    isDefined(categories) && {
                        label: "Attribute Category",
                        value: categories?.find(
                            (e) => e.id === state.attribute.category
                        )?.name,
                        onRemove: () => {
                            removeFilter({
                                attribute: GlobalFiltersInitialState.attribute,
                            });
                        },
                    },
                isDefined(state.attribute.type) &&
                    isDefined(selectedtype?.count) && {
                        label: "Attribute Type",
                        value: selectedtype.results[0]?.name,
                        onRemove: () => {
                            removeFilter({
                                attribute: {
                                    category: state.attribute.category,
                                    type: null,
                                    value: null,
                                },
                            });
                        },
                    },
                isDefined(state.attribute.value) && {
                    label: "Attribute Value",
                    value: state.attribute.value,
                    onRemove: () => {
                        removeFilter({
                            attribute: {
                                category: state.attribute.category,
                                type: state.attribute.type,
                                value: null,
                            },
                        });
                    },
                },
            ].filter(Boolean),
        },
        {
            heading: DrawerFilterHeadings.processingStatus,
            Component: ProcessingStatusFilter,
            filterKey: "processingStatus",
            appliedFilters: processingStatusOptions
                .filter((e) => e.id === state.processingStatus)
                .map((e) => ({
                    value: e.value,
                    onRemove: () => {
                        removeFilter({
                            processingStatus:
                                GlobalFiltersInitialState.processingStatus,
                        });
                    },
                })),
        },
    ].filter(Boolean) as Array<{
        heading: string;
        Component: React.FC;
        filterKey: keyof DrawerFiltersType;
        appliedFilters?: Array<AppliedFilters>;
    }>;

    const staticAppliedFilters: Array<AppliedFilters> = [
        state.meetingType !== GlobalFiltersInitialState.meetingType &&
            appliedFilterKeys.includes("meetingType") &&
            isDefined(state.meetingType) && {
                label: "Meeting Type",
                value: state.meetingType,
                onRemove: () => {
                    removeFilter({
                        meetingType: GlobalFiltersInitialState.meetingType,
                    });
                },
            },

        ...(!!state.teams?.length &&
        appliedFilterKeys.includes("teams") &&
        dashboardType !== "Account"
            ? (allTeams ?? [])
                  .filter((e) => state.teams.includes(e.id))
                  .map((e) => ({
                      label: "Teams",
                      value: e.name,
                      onRemove: () => {
                          removeFilter({
                              teams: state.teams.filter((id) => id !== e.id),
                          });
                      },
                  }))
            : []),

        ...(!!state.accountTeams?.length &&
        dashboardType === "Account" &&
        appliedFilterKeys.includes("accountTeams")
            ? (allTeams ?? [])
                  .filter((e) => state.accountTeams?.includes(e.id))
                  .map((e) => ({
                      label: "Owner Teams",
                      value: e.name,
                      onRemove: () => {
                          removeFilter({
                              accountTeams: state.accountTeams?.filter(
                                  (id) => id !== e.id
                              ),
                          });
                      },
                  }))
            : []),

        ...(!!state.reps?.length &&
        appliedFilterKeys.includes("reps") &&
        dashboardType !== "Account"
            ? (users ?? [])
                  .filter((e) => setReps.has(e.id))
                  .map((e) => ({
                      label: "Reps",
                      value: getUserName(e),
                      onRemove: () => {
                          const reps = state.reps.filter((id) => id !== e.id);
                          removeFilter({
                              reps,
                          });
                      },
                  }))
            : []),

        ...(!!state.accountReps?.length &&
        dashboardType === "Account" &&
        appliedFilterKeys.includes("accountReps")
            ? (users ?? [])
                  .filter((e) => setReps.has(e.id))
                  .map((e) => ({
                      label: "Owner Reps",
                      value: getUserName(e),
                      onRemove: () => {
                          removeFilter({
                              accountReps: state.accountReps.filter(
                                  (id) => id !== e.id
                              ),
                          });
                      },
                  }))
            : []),

        state.durationKey !== GlobalFiltersInitialState.durationKey &&
            appliedFilterKeys.includes("durationKey") && {
                label: "Duration",
                value: state?.durationOptions?.[state?.durationKey]?.name,
                onRemove: () => {
                    removeFilter({
                        durationKey: GlobalFiltersInitialState.durationKey,
                    });
                },
            },

        state.dateKey !== GlobalFiltersInitialState.dateKey &&
            appliedFilterKeys.includes("dateKey") && {
                label: "Date",
                value: state.dateOptions[state.dateKey].name,
                onRemove: () => {
                    removeFilter({
                        dateKey: GlobalFiltersInitialState.dateKey,
                    });
                },
            },
        ,
    ].filter(Boolean);
    return {
        filtersList: filters.map(({ appliedFilters: _, ...rest }) => ({
            ...rest,
        })),
        appliedFilters: [
            ...staticAppliedFilters,
            ...filters
                .filter((e) => appliedFilterKeys.includes(e.filterKey))
                .map((e) => [
                    ...(e.appliedFilters?.map((filter) => ({
                        ...filter,
                        label: filter.label ?? e.heading,
                    })) ?? []),
                ])
                .flat(),
        ],
    };
}
