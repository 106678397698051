import GenericRadioGroup from "@convin/components/select/GenericRadioGroup";

import useGlobalFiltersStateContext from "../../../../../../hooks/useGlobalFiltersStateContext";
import { Level } from "@convin/type/Filters.model";
import { GlobalFiltersInitialState } from "@convin/contexts/GlobalFiltersStateContextProvider";
import { useGetDispositionTemplateListQuery } from "@convin/redux/services/settings/aiDisposition.service";
import { isDefined } from "@convin/utils/helper/common.helper";
import { useGetAllReportsQuery } from "@convin/redux/services/home/analyticsDashboard.service";
import { useParams } from "react-router-dom";

const LevelFilter = () => {
    const { state: filtersState, updateState: updateFiltersState } =
        useGlobalFiltersStateContext();
    const { data: allReports } = useGetAllReportsQuery();
    const { report_type } = useParams<{
        report_type: string;
    }>();
    const { applicable_filters = [] } =
        allReports?.find((e) => e.type === report_type) ?? {};
    const { data: dispositionTemplates } = useGetDispositionTemplateListQuery(
        {
            isArchive: false,
        },
        {
            skip: !applicable_filters.includes("ai_disposition_filter"),
        }
    );

    return (
        <GenericRadioGroup<string>
            value={Boolean(filtersState.level) ? "conversation" : "account"}
            handleChange={(val) => {
                let dispositionTemplateId: number | null = null;
                if (applicable_filters.includes("ai_disposition_filter")) {
                    dispositionTemplateId =
                        dispositionTemplates?.filter(
                            (dispositionTemplate) =>
                                dispositionTemplate.disposition_scope ===
                                (val === "account" ? "account" : "meeting")
                        )[0]?.id ?? null;
                }

                updateFiltersState({
                    level:
                        val === "account" ? Level.account : Level.conversation,
                    ...(val === "account"
                        ? {
                              tags: GlobalFiltersInitialState.tags,
                              agentTenure: null,
                          }
                        : {
                              accountTags:
                                  GlobalFiltersInitialState.accountTags,
                          }),
                    ...(isDefined(dispositionTemplateId) && {
                        dispositionTemplate: {
                            template: dispositionTemplateId,
                            level1: null,
                            level2: null,
                            level3: null,
                            disposition_scope:
                                val === "account" ? "account" : "meeting",
                        },
                    }),
                });
            }}
            options={[
                {
                    id: "conversation",
                    label: "Conversation",
                },
                { id: "account", label: "Account" },
            ]}
            className="flex"
            sx={{ gap: 4 }}
        />
    );
};

export default LevelFilter;
