import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import {
    AiDispositionParsedDataType,
    AiDispositionPayloadType,
    AiDispositionTemplateResultType,
} from "@convin/type/AiDisposition";

export const aiDispositionApiSlice = createApi({
    reducerPath: "aiDispositionApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    tagTypes: ["Templates"],
    endpoints: (builder) => ({
        getDispositionTemplateList: builder.query<
            AiDispositionTemplateResultType[],
            { isArchive: boolean }
        >({
            query: (payload) => ({
                url: "/gpt/ai_disposition/",
                method: "GET",
                params: { is_archived: payload.isArchive ? "true" : "false" },
            }),
            providesTags: [{ type: "Templates", id: "ALL" }],
        }),
        getDispositionTemplateById: builder.query<
            AiDispositionTemplateResultType,
            number
        >({
            query: (id) => ({
                url: `/gpt/ai_disposition/${id}/`,
                method: "GET",
            }),
        }),
        getDispositionTemplateFiltersById: builder.query<
            Pick<
                AiDispositionTemplateResultType,
                "id" | "name" | "disposition_scope" | "dispositions"
            >,
            number
        >({
            query: (id) => ({
                url: `gpt/ai_disposition/filters/${id}/`,
                method: "GET",
            }),
        }),
        archiveDispositionTemplate: builder.mutation<
            AiDispositionTemplateResultType,
            { is_archived: boolean; id: number }
        >({
            query: ({ id, is_archived }) => ({
                url: `/gpt/ai_disposition/${id}/`,
                method: "PATCH",
                body: { is_archived },
            }),
            invalidatesTags: [{ type: "Templates", id: "ALL" }],
        }),
        createDispositionTemplate: builder.mutation<
            AiDispositionTemplateResultType,
            AiDispositionPayloadType
        >({
            query: (payload) => ({
                url: "/gpt/ai_disposition/",
                method: "POST",
                body: payload,
            }),
        }),
        updateDispositionTemplate: builder.mutation<
            AiDispositionTemplateResultType,
            { payload: FormData; id: number }
        >({
            query: ({ id, payload }) => ({
                url: `/gpt/ai_disposition/${id}/`,
                method: "PATCH",
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                body: payload,
            }),
        }),
        validateDispositionTemplate: builder.mutation<
            { validate: true; dispositions: AiDispositionParsedDataType },
            FormData
        >({
            query: (payload) => ({
                url: "/gpt/ai_disposition/",
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                params: { validate: "true" },
                body: payload,
            }),
        }),
    }),
});

export const {
    useGetDispositionTemplateListQuery,
    useArchiveDispositionTemplateMutation,
    useGetDispositionTemplateByIdQuery,
    useCreateDispositionTemplateMutation,
    useValidateDispositionTemplateMutation,
    useUpdateDispositionTemplateMutation,
    useGetDispositionTemplateFiltersByIdQuery,
} = aiDispositionApiSlice;
