import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

export default function AssessmentSvg({
    sx,
    className,
}: {
    sx?: SxProps<Theme>;
    className?: string;
}): ReactElement {
    return (
        <SvgIcon
            viewBox="0 0 24 24"
            className={className}
            sx={{ width: 30, height: 30, fill: "none", ...sx }}
        >
            <mask
                id="mask0_20060_2502"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
            >
                <rect width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_20060_2502)">
                <path
                    d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H14L20 8V11C19.6167 11.0833 19.2583 11.2167 18.925 11.4C18.5917 11.5833 18.2833 11.8167 18 12.1L12 18.075V22H6ZM14 22V18.925L19.525 13.425C19.675 13.275 19.8417 13.1667 20.025 13.1C20.2083 13.0333 20.3917 13 20.575 13C20.775 13 20.9667 13.0375 21.15 13.1125C21.3333 13.1875 21.5 13.3 21.65 13.45L22.575 14.375C22.7083 14.525 22.8125 14.6917 22.8875 14.875C22.9625 15.0583 23 15.2417 23 15.425C23 15.6083 22.9667 15.7958 22.9 15.9875C22.8333 16.1792 22.725 16.35 22.575 16.5L17.075 22H14ZM20.575 16.4L21.5 15.425L20.575 14.5L19.625 15.45L20.575 16.4ZM13 9H18L13 4V9Z"
                    fill="currentColor"
                />
            </g>
        </SvgIcon>
    );
}
