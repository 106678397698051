import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function CompletedDocumentSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
    noBorder?: boolean;
}) {
    return (
        <SvgIcon
            viewBox="0 0 32 32"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
        >
            <mask
                id="mask0_11326_8503"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="7"
                y="7"
                width="18"
                height="18"
            >
                <rect x="7" y="7" width="18" height="18" fill="#D9D9D9" />
            </mask>
            <path
                d="M10.5556 23C10.1278 23 9.76157 22.8368 9.45694 22.5104C9.15231 22.184 9 21.7917 9 21.3333V9.66667C9 9.20833 9.15231 8.81597 9.45694 8.48958C9.76157 8.16319 10.1278 8 10.5556 8H18.3333L23 13V21.3333C23 21.7917 22.8477 22.184 22.5431 22.5104C22.2384 22.8368 21.8722 23 21.4444 23H10.5556ZM12.1111 19.6667H19.8889V18H12.1111V19.6667ZM12.1111 16.3333H19.8889V14.6667H12.1111V16.3333ZM12.1111 13H17.5556V11.3333H12.1111V13Z"
                fill="#1AC468"
            />
        </SvgIcon>
    );
}
