import { useContext, useState } from "react";
import { WebSocketContext } from "@convin/components/websocket/context/WebSocketContextProvider";
import { getDomain } from "@convin/utils/helper/common.helper";
import {
    GeneralNotificationHistory,
    NotificationHistory,
    WebSocketMessage,
} from "@convin/type/WebSocketNotification";
import useAuth from "@convin/hooks/useAuth";
import {
    analyticsDashboardApiSlice,
    useGetNotificationListQuery,
} from "@convin/redux/services/home/analyticsDashboard.service";
import { useDispatch } from "react-redux";

export interface IWebSocketNotification {
    id: string;
    type: string;
    data: NotificationHistory["messages"];
    timestamp: string;
}

export function useWebSocketNotifications() {
    const { socketRef, commandId } = useContext(WebSocketContext);
    const [isLoading, setIsLoading] = useState(false);
    const { data: notificationList } = useGetNotificationListQuery();
    const dispatch = useDispatch();

    const { user } = useAuth();

    const fetchNotificationsHistory = async (
        lastEvaluatedKey: string | null
    ) => {
        if (!socketRef.current || isLoading) return;
        if (!user) return;

        setIsLoading(true);
        try {
            const domain = getDomain();
            const historyCommand = {
                history: {
                    limit: 50,
                    channel: `$${domain}_${user?.id}`,
                    persistent_only: true,
                    last_evaluated_key: lastEvaluatedKey,
                },
                id: commandId.current!++,
            };

            // Send history command through websocket
            socketRef.current.send(JSON.stringify(historyCommand));

            // Handle the response in the message event listener
            const messageHandler = (event: MessageEvent) => {
                try {
                    const data = JSON.parse(event.data) as WebSocketMessage;
                    if (data.id === historyCommand.id) {
                        const notifications = data.history?.messages || [];
                        dispatch(
                            analyticsDashboardApiSlice.util.updateQueryData(
                                "getNotificationList",
                                undefined,
                                (draft) => {
                                    draft.notifications = [
                                        ...draft.notifications,
                                        ...(notifications as GeneralNotificationHistory[]),
                                    ];
                                    draft.lastEvaluatedKey =
                                        data.history?.last_evaluated_key ||
                                        null;
                                    return draft;
                                }
                            )
                        );
                        setIsLoading(false);
                        socketRef.current?.removeEventListener(
                            "message",
                            messageHandler
                        );
                    }
                } catch (error) {
                    console.error("Error parsing websocket message:", error);
                }
            };

            socketRef.current.addEventListener("message", messageHandler);
        } catch (error) {
            setIsLoading(false);
        }
    };
    const clearNotification = (payload: {
        notification_id?: string;
        clear_all?: boolean;
    }) => {
        if (!socketRef.current) return;
        const domain = getDomain();
        const notificationCommand = {
            clear_notifications: {
                channel: `$${domain}_${user?.id}`,
                ...payload,
            },
            id: commandId.current!++,
        };

        socketRef!.current.send(JSON.stringify(notificationCommand));
        if (payload.clear_all) {
            dispatch(
                analyticsDashboardApiSlice.util.updateQueryData(
                    "getNotificationList",
                    undefined,
                    (draft) => {
                        draft.notifications = [];
                        draft.unreadCount = 0;
                    }
                )
            );
        } else {
            dispatch(
                analyticsDashboardApiSlice.util.updateQueryData(
                    "getNotificationList",
                    undefined,
                    (draft) => {
                        draft.notifications = draft.notifications.filter(
                            (notification) =>
                                notification.data.notification_id !==
                                payload.notification_id
                        );
                        draft.unreadCount = draft?.unreadCount
                            ? draft.unreadCount - 1
                            : null;
                        return draft;
                    }
                )
            );
        }
    };
    const loadMore = async () => {
        if (notificationList?.lastEvaluatedKey && !isLoading) {
            await fetchNotificationsHistory(notificationList.lastEvaluatedKey);
        }
    };
    return {
        notifications: notificationList?.notifications || [],
        isLoading,
        loadMore,
        hasNext: notificationList?.lastEvaluatedKey !== null,
        clearNotification,
    };
}
