import VirtualList from "@convin/components/custom_components/VirtualList/VirtualList";
import {
    GeneralNotificationHistory,
    INotification,
    NotificationType,
} from "@convin/type/WebSocketNotification";
import { useGetNotificationListQuery } from "@convin/redux/services/home/analyticsDashboard.service";
import {
    alpha,
    Box,
    Button,
    Divider,
    Grid2,
    IconButton,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { useWebSocketNotifications } from "../hooks/useWebSocketNotifications";
import { Close } from "@mui/icons-material";
import { isDefined } from "@convin/utils/helper/common.helper";
import { getDateTime } from "@convin/utils/helper/date.helper";
import { Label } from "@convin/components/custom_components/Typography/Label";
import NoDownloads from "./NoDownloads";
import FailedDocumentSvg from "@convin/components/svg/FileFormats/FailedDocument";
import CompletedDocumentSvg from "@convin/components/svg/FileFormats/CompeletedDocumentSvg";
import AssessmentSvg from "@convin/components/svg/AssessmentSvg";

/**
 * look for the notification list in the notificationList.notifications
 * check the push command if the notification is there in the list update it.
 * if the notification is not there in the list then check 1 ) if it is updated -> do nothing 2 ) if it is not updated -> add it to the notificationList.notifications
 */

const commonIconSx = {
    height: 15,
    width: 15,
};

const NotificationCard = ({
    data,
    prevIndexedData,
}: {
    data: GeneralNotificationHistory;
    prevIndexedData: GeneralNotificationHistory | null;
}) => {
    const theme = useTheme();
    const icons: Record<
        NotificationType,
        Record<
            "COMPLETED" | "FAILED",
            {
                bgColor: string;
                icon: React.ReactNode;
            }
        >
    > = {
        "library.meeting.transcription.status": {
            COMPLETED: {
                bgColor: alpha(theme.palette.auditColors.good, 0.1),
                icon: (
                    <CompletedDocumentSvg
                        sx={{
                            height: 30,
                            width: 30,
                        }}
                    />
                ),
            },
            FAILED: {
                bgColor: alpha(theme.palette.error.main, 0.2),
                icon: <FailedDocumentSvg sx={commonIconSx} />,
            },
        },
        "coachings.assessment.status": {
            COMPLETED: {
                bgColor: alpha(theme.palette.primary.main, 0.1),
                icon: (
                    <Box
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    >
                        <AssessmentSvg
                            sx={{
                                ...commonIconSx,
                            }}
                        />
                    </Box>
                ),
            },
            FAILED: {
                bgColor: alpha(theme.palette.error.main, 0.2),
                icon: <AssessmentSvg sx={commonIconSx} />,
            },
        },
    };
    const { clearNotification } = useWebSocketNotifications();
    const previousCreatedAt =
        (prevIndexedData?.metadata?.created_at as number) * 1000;
    const currentCreatedAt = (data.metadata?.created_at as number) * 1000;

    const prevDay = isDefined(previousCreatedAt)
        ? getDateTime({
              isoDate: previousCreatedAt,
              format: "dd MM 'YY",
          })
        : null;

    const currDay = getDateTime({
        isoDate: currentCreatedAt,
        format: "dd MM 'YY",
    });

    const isToday =
        currDay ===
        getDateTime({
            isoDate: new Date(),
            format: "dd MM 'YY",
        });

    const isYesterday =
        currDay ===
        getDateTime({
            isoDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            format: "dd MM 'YY",
        });

    const displayTime = getDateTime({
        isoDate: currentCreatedAt,
        format: "T",
    });
    if (!data.data) return null;
    return (
        <div className={"flex flex-col gap-1 border-b border-b-gray-200 pt-2"}>
            {currDay !== prevDay ? (
                <Label
                    colorType="999"
                    variant="medium"
                    className="font-semibold"
                >
                    {isToday ? "Today" : isYesterday ? "Yesterday" : currDay}
                </Label>
            ) : null}

            <Grid2
                columns={24}
                container
                gap={1}
                className="flex"
                sx={{
                    py: 2,
                }}
            >
                <Grid2 size={2}>
                    <Box
                        sx={{
                            backgroundColor: () =>
                                icons[data.data.notification_type][
                                    data.data.response.status
                                ].bgColor,
                        }}
                        className="h-8 w-8  rounded-full flex items-center justify-center"
                    >
                        {
                            icons[data.data.notification_type][
                                data.data.response.status
                            ].icon
                        }
                    </Box>
                </Grid2>
                <Grid2 size={18} className="flex flex-col gap-1">
                    <Label
                        colorType="333"
                        variant="subtitle2"
                        hasTooltip
                        isEllipses
                    >
                        {data.data.title}
                    </Label>
                    <Label
                        colorType="666"
                        hasTooltip
                        isEllipses
                        variant="textXs"
                    >
                        {data.data.message}
                    </Label>
                    <Label colorType="999" variant="extraSmall">
                        {displayTime}
                    </Label>
                    {data.data.response && "actions" in data.data.response && (
                        <div className="flex flex-wrap">
                            {data.data.response?.actions?.map((action) => (
                                <Button key={action.label}>
                                    {action.label}
                                </Button>
                            ))}
                        </div>
                    )}
                </Grid2>
                <Grid2 size={3} className="flex flex-col items-center gap-1">
                    <IconButton
                        onClick={() =>
                            clearNotification({
                                notification_id: data.data.notification_id,
                            })
                        }
                    >
                        <Close />
                    </IconButton>
                </Grid2>
            </Grid2>
        </div>
    );
};
export default function GeneralNotificationsTab() {
    const { data } = useGetNotificationListQuery();
    const { isLoading, loadMore, hasNext, clearNotification } =
        useWebSocketNotifications();
    return (
        <Stack className="w-full h-full overflow-auto">
            {!!data?.notifications.length ? (
                <div className="flex-1 overflow-auto">
                    <VirtualList<{
                        data: INotification;
                        metadata: Record<string, string | number>;
                    }>
                        data={data?.notifications || []}
                        itemHeight={50}
                        hasNext={hasNext}
                        isLoading={isLoading}
                        fetchNext={loadMore}
                        sx={{
                            px: 2,
                            py: 0,
                        }}
                        Component={NotificationCard}
                    />
                </div>
            ) : (
                <NoDownloads copy="notifications" />
            )}
            {data?.notifications.length !== 0 && (
                <div className="flex flex-col justify-center flex-shrink-0">
                    <Divider flexItem className="flex-1" />
                    <Typography
                        component="button"
                        variant="medium"
                        color="primary.main"
                        className="font-semibold text-center"
                        p={2}
                        onClick={() => {
                            clearNotification({ clear_all: true });
                        }}
                    >
                        Clear
                    </Typography>
                </div>
            )}
        </Stack>
    );
}
