import { SvgIcon, SxProps, Theme } from "@mui/material";

export default function FailedDocumentSvg({
    sx,
}: {
    sx?: SxProps<Theme>;
    noBorder?: boolean;
}) {
    return (
        <SvgIcon
            viewBox="0 0 15 15"
            sx={{ width: 24, height: 24, fill: "none", ...sx }}
        >
            <path
                d="M10.3163 12.8945C10.4207 12.8945 10.5082 12.8592 10.5788 12.7886C10.6494 12.7179 10.6847 12.6304 10.6847 12.5261C10.6847 12.4217 10.6494 12.3342 10.5788 12.2636C10.5082 12.1929 10.4207 12.1576 10.3163 12.1576C10.2119 12.1576 10.1244 12.1929 10.0538 12.2636C9.98318 12.3342 9.94787 12.4217 9.94787 12.5261C9.94787 12.6304 9.98318 12.7179 10.0538 12.7886C10.1244 12.8592 10.2119 12.8945 10.3163 12.8945ZM9.94787 11.4208H10.6847V9.21027H9.94787V11.4208ZM10.3163 14.7366C9.80664 14.7366 9.32769 14.6399 8.87945 14.4465C8.4312 14.253 8.04129 13.9905 7.70971 13.659C7.37813 13.3274 7.11563 12.9375 6.92221 12.4892C6.72879 12.041 6.63208 11.562 6.63208 11.0524C6.63208 10.5427 6.72879 10.0638 6.92221 9.61553C7.11563 9.16729 7.37813 8.77737 7.70971 8.4458C8.04129 8.11422 8.4312 7.85172 8.87945 7.6583C9.32769 7.46487 9.80664 7.36816 10.3163 7.36816C10.8259 7.36816 11.3049 7.46487 11.7531 7.6583C12.2014 7.85172 12.5913 8.11422 12.9229 8.4458C13.2544 8.77737 13.5169 9.16729 13.7104 9.61553C13.9038 10.0638 14.0005 10.5427 14.0005 11.0524C14.0005 11.562 13.9038 12.041 13.7104 12.4892C13.5169 12.9375 13.2544 13.3274 12.9229 13.659C12.5913 13.9905 12.2014 14.253 11.7531 14.4465C11.3049 14.6399 10.8259 14.7366 10.3163 14.7366Z"
                fill="#FE1E25"
            />
            <path
                d="M6.63158 5.15789H10.3158L6.63158 1.47368V5.15789ZM1.47368 14.7368C1.06842 14.7368 0.721491 14.5925 0.432895 14.3039C0.144298 14.0154 0 13.6684 0 13.2632V1.47368C0 1.06842 0.144298 0.721491 0.432895 0.432895C0.721491 0.144298 1.06842 0 1.47368 0H7.36842L11.7895 4.42105V6.11579C11.5439 6.04211 11.2982 5.98684 11.0526 5.95C10.807 5.91316 10.5614 5.89474 10.3158 5.89474C9.61579 5.89474 8.9557 6.02675 8.33553 6.29079C7.71535 6.55482 7.17193 6.91403 6.70526 7.36842H2.94737V8.8421H5.65526C5.54474 9.07544 5.45263 9.31491 5.37895 9.56053C5.30526 9.80614 5.25 10.0579 5.21316 10.3158H2.94737V11.7895H5.21316C5.29912 12.3421 5.47105 12.8732 5.72895 13.3829C5.98684 13.8925 6.31842 14.3439 6.72368 14.7368H1.47368Z"
                fill="#FE1E25"
            />
        </SvgIcon>
    );
}
