import { Label } from "@convin/components/custom_components/Typography/Label";
import {
    Box,
    Stack,
    SxProps,
    Theme,
    Typography,
    TypographyProps,
} from "@mui/material";
import React, { ReactElement } from "react";

type Format = {
    text: string | ReactElement;
    subtext?: string;
    Component: ReactElement;
    ButtonComponent?: ReactElement;
    typographyVariant?: TypographyProps["variant"];
    subtextVariant?: TypographyProps["variant"];
    sx?: SxProps<Theme>;
};

export default function EmptyState({
    text,
    subtext,
    Component,
    ButtonComponent,
    typographyVariant = "large",
    subtextVariant = "medium",
    sx,
}: Format): ReactElement {
    return (
        <Stack
            gap={2}
            className=" items-center justify-center flex-1 h-full"
            sx={{ ...sx }}
        >
            {Component}
            <Box className="w-[330px] text-center leading-6">
                <Typography
                    variant={typographyVariant}
                    sx={{ color: "textColors.333", fontWeight: 500 }}
                >
                    {text}
                </Typography>
                <Label colorType="666" variant={subtextVariant}>
                    {subtext}
                </Label>
            </Box>
            {ButtonComponent}
        </Stack>
    );
}
